@use "vars" as *;

/* ====================================================
Control
==================================================== */
.c-fc{ //font-color
	&--ff{
		color: #fff
	}
}



.c-ft{//font-family
	&--en{
		font-family: $font-en;
	}
	&--base{
		font-family: $font-base;
	}
}



.c-fw{//font-weight
	&--lt{
		font-weight: 300!important
	}
	&--rg{
		font-weight: 400!important
	}
	&--md{
		font-weight: 500!important
	}
	&--sbd{
		font-weight: 600!important
	}
	&--bd{
		font-weight: 700!important
	}
	&--bk{
		font-weight: 900!important
	}
}



.c-tx--v {//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc{
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}



.c-al{//text-align
	&--c{
		text-align: center!important
	}
	&--r{
		text-align: right!important
	}
	&--l{
		text-align: left!important
	}
}



.c-val{//vertical-align
	&--md{
		vertical-align: middle
	}
	&--top{
		vertical-align: top
	}
}





/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt{
	&__01{//17/30
		@include fts(17,30);
		@include mq(xl){
			@include fts(15,26);
		}
		@include mq {
			@include fts(14,25);
		}
	}
	&__02{//14/23.81
		@include fts(14,23.81);
		@include mq {
			line-height: calc(22/14);
		}
	}
	&__03{//16/28
		@include fts(16,28);
		@include mq {
			@include fts(15, 25);
		}
	}
	&__04{//18/28
		@include fts(18,28);
		@include mq {
			@include fts(14, 25);
		}
	}
}

.c-ttl{
	&__01{
		text-align: center;
		color: #ab9572;
		padding-top: 35px;
		letter-spacing: 3px;
		margin-bottom: 40px;
		// font-weight: bold;
		font-weight: 400;
		position: relative;
		font-family: $font-en;
		@include font-size(38);
		@include mq(){
			letter-spacing: 2.5px;
			margin-bottom: 25px;
			padding-top: 25px;
			@include font-size(26.5);
		}
		@include p-before(154px,33px){
			background: url('../img/index/icon-ttl.png') center no-repeat;
			background-size: contain;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			@include mq(){
				width: 109px;
				height: 24px;
			}
		}
		&--white{
			color: #fff;
			&::before{
				background: url('../img/index/icon-ttl-white.png') center no-repeat;
				background-size: contain;
			}
		}
	}
	&__02{
		@include font-size(37.95);
		text-align: center;
		background: url(../img/contact/ico-ttl.png) bottom center no-repeat;
		color: #ad9877;
		padding-bottom: 21px;
		font-weight: bold;
		@include mq {
			@include font-size(29.865);
			background-size: 30px;
			padding-bottom: 16px;
		}
		&--brown{
			color: #937a56;
		}
	}
	&__03 {
		@include fts(24,34);
		font-weight: bold;
		text-align: center;
		color: #937a56;
		@include mq {
			@include fts(18,28);
		}
		@include mq(xs) {
			@include font-size(16);
		}
		&--grey{
			color: #999187;
		}
		&--border{
			border-bottom: 2px solid #b19c7a;
			position: relative;
			text-align: left;
			padding-bottom: 15px;
			@include mq {
				border-bottom-width: 1px;
				padding-bottom: 10px;
			}
			&::before,
			&::after {
				position: absolute;
				content: '';
				height: 10px;
				width: 160px;
				top: 100%;
				@include mq {
					height: 5px;
					width: 80px;
				}
			}
			&::before {
				left: 0;
				background: #ebe0ce;
			}
			&::after {
				left: 160px;
				background: #b29d7c;
				@include mq {
					left: 80px;
				}
			}
		}
	}
}



// Common Button style
.c-btn{
	&__01{
		border-radius: 6px;
		width: 100%;
		max-width: 270px;
		height: 66px;
		@include flex(center,center);
		flex-direction: column;
		margin: 0 auto;
		background: url('../img/index/bg-btn.png') center no-repeat;
		background-size: cover;
		color: #fff;
		position: relative;
		font-weight: 500;
		@include font-size(22);
		border: none;
		cursor: pointer;
		@include smooth-transition;
		@include mq {
			@include font-size(18.7);
			height: 56px;
		}
		@include mq(sm) {
			max-width: 100%;
			border-radius: 3px;
		}
		@include p-before(31px,59px){
			background: url('../img/index/icon-before.png') center no-repeat;
			background-size: contain;
			left: 2px;
			top: 4px;
			@include mq(){
				left: 3px;
				top: 3px;
				width: 28px;
				height: 49px;
			}
		}
		@include p-after(31px,59px){
			background: url('../img/index/icon-after.png') center no-repeat;
			background-size: contain;
			right: 2px;
			top: 4px;
			@include mq(){
				width: 28px;
				height: 49px;
				right: 3px;
				top: 3px;
			}
		}
		&:hover {
			@include mq(md-min) {
				opacity: 0.7;
			}
		}
		small{
			@include font-size(12);
			font-weight: 400;
			font-family: $font-gothic;
			@include mq(){
				@include font-size(12.5);
			}
		}
		&--white{
			background: url('../img/index/bg-btn-white.png') center no-repeat;
			background-size: cover;
			color: #706048;
			&::before{
				background: url('../img/index/icon-after02.png') center no-repeat;
				background-size: contain;
			}
			&::after{
				background: url('../img/index/icon-before02.png') center no-repeat;
				background-size: contain;
			}
		}
		@include mq(){
			max-width: 380px;
		}
		&.isDisable {
			pointer-events: none;
		}
		&--short {
			max-width: 270px;

			@include mq {
				max-width: 350px;
			}
		}
	}
	&__02 {
		@include font-size(14);
		color: #ad9877;
		border: 1px solid #ad9877;
		display: inline-block;
		text-align: center;
		position: relative;
		padding: 6px 5px;
		width: 150px;
		.arr {
			position: absolute;
			top: 0;
			bottom: 0;
			background: #ad9877;
			width: 31px;
			&::before {
				position: absolute;
				content: '';
				width: 10px;
				height: 10px;
				transform: rotate(45deg);
				@include v-h-align;
			}
		}
		&--arrLeft,
		&--arrRight {
			width: 132px;
		}
		&--arrLeft {
			padding-left: 32px;
			.arr {
				left: 0;
				&::before {
					border-bottom: 1px solid;
					border-left: 1px solid;
					border-color: white;
				}
			}
		}
		&--arrRight {
			padding-right: 32px;
			.arr {
				right: 0;
				&::before {
					border-top: 1px solid;
					border-right: 1px solid;
					border-color: white;
				}
			}
		}
	}
}