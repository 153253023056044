@use "vars" as *;

/* ====================================================
Header
==================================================== */
.main-header{
	&__top{
		display: none;
		@include mq(){
			display: block;
			background: #c9b698;
			padding: 9px 15px;
			text-align: center;
			img{
				width: 44px;
			}
		}
	}
	&__inner{
		// display: none;
		top: 24px;
		left: 23px;
		width: 100%;
		max-width: 222px;
		padding: 40px 4px 30px 8px;
		background-color: rgba(175, 149, 108, 0.851);
		z-index: 99;
		position: fixed;
		@include mq(xxl){
			padding: 20px 3px;
		}
		@include mq(){
			display: none;
			right: 0;
			padding: 13px 5px;
			left: auto;
		    top: 70px;
			max-width: 261px;
		}
	}
	&__ct{
		max-height: 80vh;
		overflow-y: auto;
		@include mq(){
			height: 70vh;
		}
	}
	&__logo{
		text-align: center;
		margin-bottom: 34px;
		width: 100%;
		@include mq(xxl){
			margin-bottom: 20px;
		}
		@include mq(){
			padding: 0 15px;
			margin-bottom: 11px;
			text-align: left;
		}
		img{
			width: 105px;
			display: inline-block;
			@include mq(xxl){
				width: 80px;
			}
			@include mq(){
				width: 44px;
			}
		}
	}
	&__navGlobal{
		width: 100%;
	}
	&__menu{
		width: 100%;
		&-item{
			border-bottom: 1px solid #fff;
			&:first-child{
				border-top: 1px solid #fff;
			}
			a{
				color: #fff;
				display: block;
				padding: 15px 22px 12.5px;
				font-weight: 700;
				
				@include font-size(18);
				@include mq(xxl){
					padding: 10px 15px;
				}
				@include mq(){
					padding: 13px 28px 12.5px;
					@include font-size(17);
				}
			}
		}
	}
	&__contact{
		@include flex(center);
		padding: 29px 0 22px;
		border-bottom: 1px solid #fff;
		@include mq(xxl){
			padding: 15px 0;
		}
		@include mq(){
			padding: 26px 17px 23px;
			margin-bottom: 7px;
			justify-content: flex-end;
		}
		&-item{
			padding: 0 10px;
			@include mq(){
				padding: 0 9px;
			}
			a{
				display: block;
				img{
					width: 37px;
					@include mq(){
						width: 35px;
					}
				}
			}
		}
	}
	&__tel{
		padding: 20px 10px 17px;
		border-bottom: 1px solid #fff;
		@include mq(){
			display: none;
		}
		a{
			@include flex(0,center);
			color: #fff;
			letter-spacing: 1.65px;
			padding-top: 1px;
			font-family: $font-en;
			@include font-size(19);
		}
		span{
			margin-right: 7px;
			img{
				width: 28px;
			}
		}
	}
	&__address{
		padding: 32px 17px 25px;
		letter-spacing: 1px;
		color: #fff;
		font-weight: 300;
		line-height: 1.428;
		@include font-size(14);
		@include mq(xxl){
			padding: 15px 10px;
		}
		@include mq(){
			display: none;
		}
	}
	&__privacy{
		@include font-size(14);
		color: #fff;
		display: block;
		font-weight: 300;
		text-align: center;
		padding: 5px 14px 6px 10px;
		letter-spacing: 1.5px;
		border: 1px solid #fff;
		max-width: 206px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		@include p-after(6px,6px){
			border-top: 1px solid #fff;
			border-right: 1px solid #fff;
			transform: rotate(45deg) translateY(-50%);
			top: 47%;
			right: 13px;
		}
		@include mq(){
			margin-bottom: 14px;
			padding: 6px 14px 6px 10px;
			max-width: 250px;
			@include font-size(13);
		}
	}
	&__close{
		width: 30px;
		position: absolute;
		bottom: -30px;
		left: 0;
		cursor: pointer;
		transition: all 0.3s;
		@include mq(){
			position: static;
			width: 100%;
			text-align: center;
			img{
				width: 24px;
			}
		}
		&:hover{
			transition: all 0.3s;
			opacity: 0.7;
		}
	}
	&__mobile{
		display: none;
		position: fixed;
		z-index: 98;
		left: 0;
		top: 146px;
		width: 102px;
		cursor: pointer;
		transition: all 0.3s;
		&:hover{
			transition: all 0.3s;
			opacity: 0.7;
		}
		@include mq(xl){
			width: 80px;
		}
		@include mq(){
			display: none;
		}
		img{
			width: 100%;
		}
		&.on{
			display: block;
			transition: all 0.3s;
			@include mq(){
				display: none !important;
			}
		}
	}
}

.contact-fixed{
	position: fixed;
	bottom: 0;
	left: 0;
	display: none;
	width: 100%;
	z-index: 99;
	background-color: rgba(130, 104, 64, 0.749);
	padding: 7px 9px 3px;
	@include mq(){
		@include flex();
	}
	&__menu{
		@include flex();
		width: calc(100% - 95px);
		padding-right: 4px;
		&-item{
			margin-right: 4px;
			width: calc(50% - 2px);
			&:nth-child(2){
				margin-right: 0;
			}
			a{
				min-height: 45px;
				@include flex(center,center,wrap,center);
				border-radius: 3px;
				background: #fff;
				text-align: center;
				padding: 3px 4px 4px;
				font-family: $font-gothic;
				font-weight: bold;
				color: #af956c;
				@include font-size(10.5);
				span{
					display: block;
					margin-bottom: 5px;
					width: 100%;
					picture,img{
						max-width: 21px;
						max-height: 19px;
					}
				}
			}
		}
	}
	&__mobile{
		width: 95px;
		@include flex();
		&-item{
			margin-right: 4px;
			width: calc(50% - 2px);
			&:nth-child(2){
				margin-right: 0;
			}
		}
	}
}