@use "vars" as *;

/* ====================================================
Layout
==================================================== */
.wrapper{
	// padding-top: 115px;
	// @include mq(tb){
	// 	padding-top: 70px;
	// }
}

.inner{
	width: 90%;
	margin: 0 auto;
	max-width: 1100px;
	@include mq() {
		width: 100%;
		padding: 0 12px;
	}
	&--1265{
		max-width: 1330px;
		width: 100%;
		padding: 0 30px;
	}
	&--1278{
		max-width: 1338px;
		width: 100%;
		padding: 0 30px;
		@include mq {
			padding: 0 8px;
		}
	}
}


.mv-page {
	&__ttl {
		position: absolute;
		left: 0;
		right: 0;
		padding: 0 15px;
		padding: 35px 15px 38px;
		top: 58%;
		transform: translateY(-50%);
		background: url(../img/blog/ttl-ico.png) top center no-repeat;
		background-size: 154px auto;
		font-family: $font-en;
		@include font-size(38);
		text-align: center;
		color: #ab9572;
		letter-spacing: .08em;
		@include mq {
			@include font-size(30);
			background-size: 123px auto;
			padding: 26px 15px 38px;
			top: 62.5%;
		}
	}
	&__img {
		img {
			display: block;
			width: 100%;
			max-height: 416px;
			min-height: 300px;
			@include object-fit(cover, center);
			@include mq {
				max-height: 300px;
				min-height: 200px;
			}
		}
	}
}

.breadcrumb {
	background: #ededed;
	font-family: $font-gothic;
	@include font-size(12);
	padding: 9px 0;
	font-weight: 400;
	@include mq {
		display: none;
	}
	.inner {
		@include flex(start, start, wrap);
		gap: 2px;
	}
	a {
		color: #937a56;
		text-decoration: underline;
		text-underline-position: under;
	}
}

//form input
.form-control{
	display: block;
	width: 100%;
	height: 42px;
	background: white;
	border: 1px solid #c9b99f;
	font-weight: 500;
	padding: 5px 16px;
	@include fts(16,28);
	outline: none;
	appearance: none;
	resize: none;
	font-family: $font-gothic;
	@include mq {
		height: 55px;
		@include fts(14,14);
	}
	&::placeholder {
		color: #acacac;
		opacity: 1;
	}
	
	&::-ms-input-placeholder {
		color: #acacac;
	}
}
textarea.form-control {
	height: 200px;
	padding-top: 10px;
	@include mq {
		line-height: calc(24/14);
		height: 255px;
		padding-top: 15px;
	}
}

.group-select {
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		position: absolute;
		top: 48%;
		right: 29px;
		width: 12px;
		height: 12px;
		transform: rotate(45deg) translateY(-50%);
		border-top: 2px solid #937a56;
		border-right: 2px solid #937a56;
		pointer-events: none;
	}
}

//radio end check box
.pure-input,
.mwform-checkbox-field label,
.mwform-radio-field label{
	position: relative;
	@include fts(16,23);
	@include mq {
		@include font-size(15);
	}
	input{
		position: absolute;
		top:  0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		opacity: 0;
		cursor: pointer;
		&[type="checkbox"]{
			& + span{
                &::before {
                    top: -1px;
                    left: 0;
                    width: 22px;
                    height: 22px;
                }
				&:after{
					background-color: transparent;
					top: 3px;
					left: 4px;
					width: 15px;
					height: 8px;
					border-style: solid;
					border-width: 0 0 2px 2px;
					border-color: white;
					border-image: none;
					transform: rotate(-45deg) scale(0);
				}
			}
			&:checked{
				& + span{
					&::before {
						background: #937a56;
					}
					&:after{
						content: '';
						transform: rotate(-45deg) scale(1);
						transition: transform 200ms ease-out;
					}
				}
			}
		}
		&[type="radio"]{
			+ span{
				&:after,&:before{
					border-radius: 50%;
				}
			}
			&:checked{
				& + span{
					&:after{
						transform: scale(1);
					}
				}
			}
		}
	}
	> span{
		display: block;
		position: relative;
		padding: 0 0 0 27px;
		vertical-align: middle;
		user-select: none;
		cursor: pointer;
		&:before{
			box-sizing: content-box;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 21px;
			height: 21px;
			border: 1px solid #937a56;
			background: white;
			text-align: center;
			transition: all 0.4s ease;
			border-radius: 4px;
		}
		&:after{
			box-sizing: content-box;
			content: '';
			background-color: #937a56;
			position: absolute;
			top: 4px;
			left: 4px;
			width: 15px;
			height: 15px;
			transform: scale(0);
			transform-origin: 50%;
			transition: transform 200ms ease-out;
		}
	}
}