@use "vars" as *;

/* ====================================================
Footer
==================================================== */

.sec-refrom{
	padding: 170px 0 197px;
	background: url('../img/common/bg-ft.png') center no-repeat;
	background-size: cover;
	@include mq(xl){
		padding: 120px 0;
	}
	@include mq(){
	    padding: 33px 0 43px;
		background: url('../img/common/bg-ft-sp.png') center no-repeat;
		background-size: cover;
	}
}

.refrom-flex{
	@include flex();
	@include mq(){
		flex-wrap: wrap;
	}
	&__ttl{
		width: 50%;
		font-weight: 500;
		color: #fff;
		padding-top: 20px;
		line-height: 1.4;
		
		@include font-size(30);
		@include mq(xl){
			@include font-size(24);
		}
		@include mq(){
			padding-top: 0;
		    margin-bottom: 71px;
			width: 100%;
			text-align: center;
			line-height: 1.556;
			@include font-size(18);
		}
		@include mq(xs){
			@include font-size(16);
		}
		span{
			display: block;
			font-weight: 900;
			color: #ffe859;
			
			margin-bottom: 22px;
			@include font-size(20);
			@include mq(xl){
				margin-bottom: 15px;
				@include font-size(18);
			}
			@include mq(){
				margin-bottom: 5px;
				@include font-size(15);
			}
		}
	}
	&__ct{
		width: 50%;
		padding-left: 81px;
		@include mq(xl){
			padding-left: 35px;
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
		}
	}
	&__tel{
		margin-bottom: 9px;
		@include mq(){
			margin-bottom: 7px;
		}
		a{
			font-weight: 500;
			color: #fff;
			
			line-height: 1;
			letter-spacing: 6px;
			@include font-size(58);
			@include mq(xl){
				letter-spacing: 2px;
				@include font-size(45);
			}
			@include mq(){
				letter-spacing: 4px;
				padding-left: 5px;
				text-align: center;
				display: block;
				@include font-size(42.5);
			}
			@include mq(xs){
				padding-left: 0;
				letter-spacing: 0;
				@include font-size(38);
			}
			span{
				letter-spacing: 0;
				font-weight: 300;
				margin-right: 7px;
				@include font-size(36);
				@include mq(xl){
					@include font-size(30);
				}
				@include mq(){
					@include font-size(26);
				}
			}
		}
	}
	&__note{
		@include flex(0,center);
		margin-bottom: 38px;
		@include mq(xl){
			margin-bottom: 25px;
			justify-content: space-between;
		}
		@include mq(){
			margin-bottom: 31px;
			justify-content: center;
		}
		p{
			&:not(:last-child){
				margin-right: 50px;
				@include mq(xl){
					margin-right: 20px;
				}
				@include mq(){
					margin-right: 28px;
				}
			}
			font-weight: 500;
			color: #ffe859;
			
			@include font-size(20);
			@include mq(xl){
				@include font-size(16);
			}
			@include mq(){
				@include font-size(15);
			}
			@include mq(xs){
				@include font-size(12);
			}
			span{
				color: #fff;
				margin-left: 7px;
				@include font-size(24);
				@include mq(xl){
					@include font-size(18);
				}
				@include mq(){
					margin-left: 4px;
					@include font-size(17.5);
				}
				@include mq(xs){
					@include font-size(15);
				}
			}
			b{
				color: #fff;
				margin-left: 7px;
			}
		}
	}
	&__email{
		color: #fff;
		@include font-size(23);
		border-radius: 3px;
		max-width: 460px;
		width: 100%;
		padding: 14px;
		position: relative;
		border: 1px solid #fff;
		@include flex(center,center);
		background-color: rgba(255, 255, 255, 0.18);
		@include mq(){
			max-width: 380px;
			margin: 0 auto;
			padding: 17px 10px 14px;
			@include font-size(19);
		}
		span{
			width: 30px;
			margin-right: 10px;
			@include mq(){
				width: 26px;
				margin-right: 6px;
			}
			img{
				width: 100%;
			}
		}
		@include p-before(31px,51px){
			background: url('../img/index/icon-before03.png') center no-repeat;
			background-size: contain;
			left: 4px;
			top: 3px;
			@include mq(){
				left: 2px;
				height: 49px;
			}
		}
		@include p-after(31px,51px){
			background: url('../img/index/icon-after03.png') center no-repeat;
			background-size: contain;
			right: 4px;
			top: 3px;
			@include mq(){
				right: 2px;
				height: 49px;
			}
		}
	}
}

.main-footer{
	&__content{
		padding: 65px 0 93px;
		background: #fff;
		@include flex(0,0,wrap);
		@include mq(){
			padding: 46px 0 37px;
		}
	}
	&__clmL{
		width: 180px;
		@include mq(){
			width: 100%;
			text-align: center;
		}
	}
	&__logo{
		text-align: center;
		margin-bottom: 23px;
		padding-right: 10px;
		padding-top: 3px;
		@include mq(){
			margin-bottom: 32px;
			padding-right: 0;
		}
		picture,img{
			width: 80px;
			@include mq(){
				width: 93px;
			}
		}
	}
	&__address{
		margin: 20px 0 10px;
		@include fts(12,18);
		font-weight: 500;
		
		color: #937a56;
		@include mq(){
			@include fts(14,21);
		}
		span{
			margin-bottom: 3px;
			display: block;
			@include font-size(21);
			@include mq(){
				margin-bottom: 5px;
				@include font-size(25);
			}
		}
	}
	&__clmR{
		width: calc(100% - 180px);
		max-width: 850px;
		margin-left: auto;
		@include mq(){
			width: 100%;
			max-width: 100%;
		}
	}
	&__nav{
		@include flex(between, start, wrap);
		max-width: 833px;
		width: 100%;
		margin-left: auto;
		@include mq(xl){
			padding-left: 50px;
		}
		@include mq(){
			display: none;
		}
	}
	&__menu{
		width: 38%;
		@include font-size(15);
		font-weight: 500;
		
		&:nth-child(2){
			width: 44%;
		}
		&:nth-child(3){
			width: 18%;
		}
		@include mq(sm){
			width: 100%;
			text-align: center;
		}
		&-item{
			margin-bottom: 22px;
			padding-right: 10px;
			a{
				padding-left: 23px;
				position: relative;
				font-weight: 500;
				color: #937a56;
				display: block;
				
				@include font-size(14);
				@include p-before(13px,13px){
					background: url('../img/common/icon-link.png') center no-repeat;
					background-size: contain;
					top: 3px;
					left: 0;
				}
			}
		}
	}
	&__copyright{
		font-family: $font-en;
		@include font-size(12);
		text-align: center;
		font-weight: 300;
		background: #877251;
	    padding: 16px;
		color: #fff;
		@include mq(){
			padding-bottom: 70px;
			@include font-size(13);
		}
	}
}


