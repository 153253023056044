@use "vars" as *;

.sec-company{
    padding: 44px 0 50px;
    @include mq(){
        padding: 39px 0 25px;
    }
    &__ttl{
        color: #937a56;
    }
}
.company-list{
    &__item{
        padding-left: 20px;
        margin-bottom: 24px;
        position: relative;
        @include mq(){
            margin-bottom: 20px;
        }
        @include p-before(15px,15px){
            background: url('../img/company/icon-check.png') no-repeat center/cover;
            top: 6px;
            left: 0;
            @include mq(){
                width: 14px;
                height: 14px;
                top: 5px;
            }
        }
        &:last-child{
            margin-bottom: 14px;
        }
        &-ttl{
            font-weight: 700;
        }
    }
}
.sec-access{
    padding-bottom: 80px;
    @include mq(){
        padding-bottom: 49px;
    }
    &__map{
        margin-bottom: 28px;
        @include mq(){
            margin-bottom: 36px;
        }
        iframe{
            border: none;
            width: 100%;
            @include mq(){
                height: 350px;
            }
        }
    }
}
.access-block{
    @include flex(0,0,wrap);
    &__lt{
        width: 65.5%;
        padding-right: 6.5%;
        @include mq(){
            width: 100%;
            padding-right: 0;
        }
    }
    &__rt{
        width: 34.5%;
        margin-top: -125px;
        @include mq(){
            width: 100%;
            margin-top: 0;
        }
    }
    &__img{
        margin-bottom: 63px;
    }
    &__btn{
        max-width: 380px;
        margin-bottom: 20px;
        @include mq(){
            height: 80px;
            margin-bottom: 41px;
            &:before{
                height: 80px;
                top:0;
                width: 36px;
            }
            &:after{
                height: 80px;
                top:0;
                width: 36px;
            }
        }
    }
}