@use "vars" as *;

.blog-page {
    padding: 96px 0 152px;
    @include mq {
        padding: 37px 0 195px;
    }
    &__des {
        a {
            text-decoration: underline;
            text-underline-position: under;
            color: #665845;
        }
    }
    &__list {
        display: grid;
        grid-template: repeat(1, 1fr);
        gap: 31px;
        margin-bottom: 123px;
        @include mq(sm) {
            gap: 43px;
            margin-bottom: 79px;
        }
        > * {
            overflow: hidden;
        }
        .is-hidden{
            display: none;
        }
        &-wrap {
            @include flex(start, start, wrap);
            background: #f8f0e4;
            box-shadow: 2px 0 8px rgba(#1d1e1e, 0.24);
            padding: 13px 14px 16px;
            @include mq(sm) {
                padding: 16px 17px 54px;
                position: relative;
            }
            &::before {
                @include mq(sm) {
                    content: "";
                    position: absolute;
                    bottom: 9px;
                    right: 10px;
                    width: 20px;
                    height: 20px;
                    border-right: 3px solid #665845;
                    border-bottom: 3px solid #665845;
                }
            }
        }
        &-left {
            width: 25.1%;
            position: relative;
            @include mq(sm) {
                width: 100%;
            }
        }
        &-img {
            @include mq(sm) {
                margin-bottom: 13px;
            }
            img {
                display: block;
                width: 100%;
            }
        }
        &-status {
            position: absolute;
            top: 7px;
            right: 5px;
            background: #f7674b;
            color: white;
            @include fts(10, 10);
            padding: 9px 8px 8px;
            @include mq {
                @include font-size(11.845);
                padding: 10px;
            }
        }
        &-ct {
            width: 74.9%;
            padding: 8px 0 0 24px;
            @include mq(sm) {
                width: 100%;
                padding: 0;
            }
            .c-txt__02 {
                font-weight: normal;
                color: #5e4a2e;
            }
        }
        &-ttl {
            @include fts(18, 28);
            margin-bottom: -1px;
            color: #5e4a2e;
            @include mq {
                @include font-size(18.95);
            }
            @include mq(sm) {
                height: 28px;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 23px;
            }
            a{
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        &-date {
            @include fts(12, 28);
            margin-bottom: 14px;
            @include mq {
                @include font-size(14.21);
                margin-bottom: 6px;
            }
        }
        &-cate {
            @include flex(0, 0, wrap);
            font-family: $font-gothic;
            @include fts(10, 10);
            margin-bottom: 15px;
            gap: 5px;
            @include mq {
                @include font-size(11.845);
                margin-bottom: 22px;
            }
            > * {
                background: #665845;
                color: white;
                padding: 9px 10px 8px;
                @include mq {
                    padding: 10px 19px;
                }
            }
        }
        .c-txt__02 {
            @include mq {
                padding: 0 3px;
            }
        }
    }
}
