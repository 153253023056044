@use "vars" as *;

.service-page {
    padding: 96px 0 101px;
    @include mq {
        padding: 35px 0 102px;
    }
    .c-txt__01 {
        color: #0a0204;
    }
    &__ttl {
        @include mq(sm) {
            text-align: left;
            padding: 0 20px;
        }
    }
    &__list {
        counter-reset: countNumber;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 94px;
        @include mq {
            gap: 80px;
        }
        > * {
            counter-increment: countNumber;
        }
        &-ttl {
            @include font-size(31.97);
            font-weight: 700;
            color: #877962;
            position: relative;
            padding-left: 38px;
            margin-bottom: 10px;
            @include mq {
                @include font-size(20);
                margin-bottom: 8px;
            }
            @include mq(sm) {
                padding: 0;
            }
            @include mq(xs) {
                @include font-size(18);
            }
            &::before {
                position: absolute;
                top: -17px;
                left: -22px;
                content: counter(countNumber);
                background: #b1513e;
                color: white;
                border-radius: 50%;
                @include fts(32,32);
                width: 45px;
                height: 45px;
                @include flex(center, center);
                @include mq {
                    left: -8px;
                }
                @include mq(sm) {
                    position: static;
                    margin: 0 auto 11px;
                }
            }
        }
        &-str {
            background: #877962;
            color: white;
            @include font-size(19.98);
            font-weight: 700;
            padding: 14px 16px;
            margin-bottom: 25px;
            @include mq {
                @include fts(14,22);
                padding: 6px 22px 5px 10px;
                margin-bottom: 15px;
            }
            @include mq(xs) {
                @include font-size(13);
            }
        }
        &-flex {
            @include flex(between,0,wrap);
            @include mq {
                align-items: flex-start;
            }
            @include mq(sm) {
                display: block;
            }
        }
        &-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            @include mq(sm-min) {
                width: 45.8%;
            }
            @include mq(sm) {
                gap: 6px;
                margin-bottom: 22px;
            }
        }
        &-ct {
            position: relative;
            padding-bottom: 85px;
            @include mq(sm-min) {
                width: 50%;
            }
            @include mq {
                @include fts(14,25);
            }
            @include mq(sm) {
                padding-bottom: 0;
            }
        }
        &-txt {
            @include mq(sm-min) {
                margin-top: -9px;
            }
            @include mq(sm) {
                margin-bottom: 19px;
            }
        }
        &-btn {
            width: 100%;
            max-width: 100%;
            @include mq(sm-min) {
                position: absolute;
                bottom: 1px;
                left: 0;
            }
        }
    }
}