@use "vars" as *;

.gallery-page {
    padding: 96px 0 151px;
    @include mq {
        padding: 36px 0 91px;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        color: #5e4a2e;
        @include mq(tb) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq(sm) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            max-width: 352px;
            margin: 0 auto;
        }
        > * {
            display: none;
            > * {
                height: 100%;
            }
        }
        .is-active {
            display: block;
        }
        &-inn {
            display: block;
            padding: 16px 15px 13px;
            background: #f8f0e4;
            box-shadow: 2px 0 8px rgba(#1d1e1e, .24);
            border-radius: 10px;
            position: relative;
            z-index: 1;
            height: 100%;
            @include mq {
                padding: 17px;
            }
            &::after {
                position: absolute;
                z-index: -1;
                content: "";
                width: 16px;
                height: 16px;
                border-right: 3px solid #5e4a2e;
                border-bottom: 3px solid #5e4a2e;
                bottom: 11px;
                right: 10px;
                @include mq {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        &-img {
            position: relative;
            margin-bottom: 10px;
            overflow: hidden;            
            &:before {
                content: "";
                display: block;
                padding-bottom: calc(176 / 267 * 100%);
            }
            @include mq {
                margin-bottom: 8px;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        &-status {
            position: absolute;
            top: 6px;
            right: 4px;
            background: #f7674b;
            color: white;
            font-family: $font-gothic;
            font-weight: bold;
            @include fts(10,10);
            padding: 9px 7px;
            @include mq {
                @include font-size(11.845);
                padding: 10px 8px;
                top: 7px;
                right: 6px;
            }
        }
        &-ttl {
            @include fts(16,28);
            font-weight: bold;
            margin-bottom: 18px;            
            @include mq {
                @include font-size(18.95);
                margin-bottom: 23px;
            }
            a{
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                display: -webkit-box;
            }
        }
        &-tags {
            @include flex(0,0,wrap);
            gap: 9px;
            @include fts(10,10);
            margin-bottom: 5px;
            font-weight: bold;
            font-family: $font-gothic;
            @include mq {
                @include font-size(11.845);
                gap: 11px;
                margin-bottom: 6px;
            }
            > * {
                border: 1px solid #5e4a2e;
                padding: 8px 9px 7px;
                @include mq {
                    padding: 9px 11px;
                }
            }
        }
        &-str {
            @include flex(0,0,wrap);
            gap: 9px;
            @include fts(10,10);
            font-weight: bold;
            font-family: $font-gothic;
            @include mq {
                @include font-size(11.845);
                gap: 11px;
            }
            > * {
                border: 1px solid #5e4a2e;
                background: #5e4a2e;
                color: white;
                padding: 8px 9px 7px;
                @include mq {
                    padding: 9px 12px;
                }
            }
        }
    }
}
