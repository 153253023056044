@use "vars" as *;

.sec-voice{
    padding: 97px 0 153px;
    @include mq(){
        padding: 36px 0 83px;
    }
}
.voice-block{
    @include flex(0,0,wrap);
    color: #0a0204;
    @include mq(){
        margin: 0 -12px;
    }
    &__lt{
        width: 50%;
        padding: 19px 20px 216px;
        background: #fffaf1;
        @include mq(){
            width: 100%;
            padding: 29px 15px 15px;
            margin-bottom: 55px;
        }
    }
    &__rt{
        width: 50%;
        padding-left: 37px;
        @include mq(){
            width: 100%;
            padding: 0 11px;
        }
        p{
            a{
                color: #b29d7c;
                display: inline;
            }
        }
    }
    &__ttl{
        margin-bottom: 30px;
        @include mq(){
            margin-bottom: 6px;
        }
    }
    p{
        margin-bottom: 30px;
        @include mq(){
            margin-bottom: 25px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .red{
            color: #ff004b;
        }
        a{
            text-decoration: underline;
        }
    }
}