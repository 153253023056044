@use "vars" as *;

/* ====================================================
Utility
==================================================== */
.pc {
	@include mq() {
		display: none !important;
	}
	&-sm {
		@include mq(sm){
			display: none !important;
		}
	}
}
.sp {
	@include mq(md-min){
		display: none !important;
	}
	&-sm {
		@include mq(sm-min){
			display: none !important;
		}
	}
}

// Hover style
.u-fn-hover{
	display: inline-block;
	@include fn-hover
}

// Position style
.u-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}

// Image Objec Fit
.u-img-of{
	width: 100%;
	height: 100%;
	@include object-fit(cover, center)
}

// Scroll Box
.c-scroll-box{
	padding-bottom: 20px;
	overflow-x: scroll;
	overflow-y: hidden;
	&-y{
		padding-bottom: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	&-sp{
		@include mq(){
			padding-bottom: 20px;
			overflow-x: scroll;
			overflow-y: hidden;
		}
	}
}

//JS scroll event
.js-u__scrollin {
	opacity: 0;
	-webkit-transform: translate(0, 70px);
	-ms-transform: translate(0, 70px);
	transform: translate(0, 70px);
}

.js-u__scrollin.is-visible {
	opacity: 1;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	-o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
