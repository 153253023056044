@use "vars" as *;

.concept-page {
    padding: 97px 0 170px;
    overflow: hidden;
    @include mq {
        padding: 35px 0 91px;
    }
    &__box {
        position: relative;
        z-index: 1;
        color: #0a0204;
        @include mq(sm-min) {
            margin-left: auto;
            width: 70.6%;
        }
        @include mq {
            @include fts(14,25);
            margin-bottom: 50px;
        }
        @include mq(sm) {
            padding-bottom: 164px;
            margin-bottom: 0;
        }
        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            width: 429px;
            height: 412px;
            background: url(../img/concept/bg.jpg) center/contain no-repeat;
            right: 105.7%;
            top: 1px;
            @include mq(sm) {
                background: url(../img/concept/bg-sp.jpg) center/contain no-repeat;
                width: 375px;
                height: 248px;
                top: auto;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%)
            }
        }
        &-ttl {
            @include font-size(24);
            line-height: 1.4;;
            font-weight: bold;
            color: #937a56;
            @include mq {
                @include fts(18,28);
            }
            @include mq(sm) {
                padding: 0 20px;
            }
        }
    }
    .c-ttl__02 {
        position: relative;
        z-index: 2;
    }
    &__des {
        position: relative;
        z-index: 2;
        color: #0a0204;
        @include mq(sm-min) {
            text-align: center;
        }
        @include mq {
            @include fts(14,25);
        }
    }
    &__flex {
        @include flex(between, start);
        position: relative;
        z-index: 2;
        @include mq {
            justify-content: flex-end;
        }
        @include mq(sm) {
            display: block;
        }
    }
    &__img {
        width: 260px;
        position: relative;
        z-index: 1;
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            background: #f4e7d4;
            width: 100%;
            height: 100%;
            top: 28px;
            right: 20px;
        }
    }
    &__info {
        margin-right: -2px;
        @include flex(end, end);
        gap: 26px;
        @include mq(sm) {
            display: block;
            padding: 0 19px;
            margin: 0 auto;
            max-width: 351px;
        }
    }
    &__avata {
        width: 260px;
        position: relative;
        z-index: 1;
        order: 1;
        @include mq(sm) {
            width: 100%;
            max-width: 312px;
            padding-right: 20px;
            margin-bottom: 54px;
        }
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            background: #f4e7d4;
            width: 100%;
            height: 100%;
            top: 24px;
            right: -19px;
            @include mq(sm) {
                top: 27px;
                right: 0;
                width: calc(100% - 20px);
            }
        }
    }
    &__ct {
        order: 0;
    }
    &__name {
        @include fts(20,21.59);
        margin-bottom: 14px;
        @include mq(sm) {
            padding: 0 3px;
            text-align: right;
            margin-bottom: 53px;
        }
    }
    &__sns {
        @include flex(end, 0);
        gap: 20px;
        line-height: 1;
        @include mq(sm) {
            padding: 0 2px 0 0;
            gap: 42px;
        }
        @include mq(xs) {
            gap: 15px;
        }
        > * {
            width: 36px;
            height: 36px;
            @include flex(center, center);
            background: #937a56;
            color: white;
            border-radius: 10px;
            @include mq(sm) {
                width: 75px;
                height: 75px;
                border-radius: 19px;
            }
            &:first-child {
                @include font-size(30);
                @include mq(sm) {
                    @include font-size(65);
                }
            }
            &:nth-child(2) {
                @include font-size(24);
                @include mq(sm) {
                    @include font-size(46);
                }
            }
            &:last-child {
                @include font-size(22);
                @include mq(sm) {
                    @include font-size(55);
                }
            }
        }
    }
}