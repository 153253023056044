@use "vars" as *;

.contact-page {
    padding: 45px 0 148px;
    @include mq {
        padding: 37px 0 90px;
    }
    &__form {
        border: 1px solid #ad9877;
    }
    &__item {
        @include flex;
        border-bottom: 1px solid #ad9877;
        min-height: 79px;
        @include mq {
            flex-wrap: wrap;
        }
        &:last-child {
            border-bottom: none;
        }
    }
    &__label {
        width: 25.4%;
        @include flex(between, center);
        background: #f7f5f1;
        border-right: 1px solid #ad9877;
        padding: 18px 25px 18px 19px;
        @include mq(tb) {
            padding: 18px 10px 18px 9px;
        }
        @include mq {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #ad9877;
            padding: 10px 16px 10px 17px;
            height: 59px;
        }
        &._start {
            @include mq(md-min) {
                align-items: flex-start;
                padding-top: 26px;
                .contact-page__require {
                    margin-top: 1px;   
                }   
            }  
        }
    }
    &__require {
        @include font-size(12);
        line-height: 1.4;
        font-family: $font-gothic;
        font-weight: bold;
        background: #937a56;
        color: white;
        @include flex(center, center);
        height: 24px;
        width: 50px;
        border-radius: 4px;
        @include mq {
            @include font-size(10.755);
            height: 22px;
            width: 35px;
            border-radius: 2px;
        }
    }
    &__ct {
        width: 74.6%;
        max-width: 712px;
        padding: 18px 24px;
        @include mq {
            width: 100%;
            max-width: 100%;
            padding: 22px 19px;
        }
    }
    &__list {
        @include flex(start, start, wrap);
        gap: 15px 38px;
        padding: 10px 0 0;
        > * {
            @include mq(sm) {
                width: 100%;
            }
        }
        &.isDisable {
            pointer-events: none;
        }
    }
    &__address {
        @include flex(start, center, wrap);
        @include font-size(18);
        margin-bottom: 18px;
        .form-control {
            width: 260px;
            margin: 0 20px 0 9px;
            @include mq {
                width: 160px;
            }
        }
        span {
            @include fts(12,12);
            width: 70px;
            height: 24px;
            @include flex(center, center);
            text-align: center;
            border: 1px solid #ad9877;
            color: #937a56;
            border-radius: 4px;
            font-weight: bold;
        }
        .error {
            display: block;
            width: 100%;
            border: none;
            order: 3;
            text-align: left;
            margin-top: 5px;
            font-size: 84% !important;
            font-weight: normal;
        }
    }
    &__txt {
        a {
            text-decoration: underline;
            text-underline-position: under;
            color: #937a56;
            margin-right: 5px;
        }
    }
    &__privacy {
        max-width: 600px;
        margin: 0 auto;
        color: #0a0204;
        .pure-input,
		.mwform-checkbox-field label{
            @include flex(center, center);
            border: 1px solid #ad9877;
            background: #f7f5f1;
            padding: 23px 10px 22px;
            @include mq {
                padding: 26px 10px;
            }
            .isDisable {
                pointer-events: none;
            }
            input {
                &[type=checkbox] {
                    & + span {
                        &::before {
                            @include mq {
                                width: 31px;
                                height: 31px;
                                left: calc(50% - 15px);
                                bottom: 0;
                                top: auto;
                            }
                        }
                    }
                    &:checked {
                        & + span {
                            &::after {
                                @include mq {
                                    width: 21px;
                                    height: 10px;
                                    top: auto;
                                    bottom: 14px;
                                    left: calc(50% - 8px);
                                }
                            }
                        }
                    }
                }
            }
            > span {
                padding: 0 7px 0 39px;
                @include mq {
                    padding: 0 0 55px;
                }
            }
        }
    }
    &__btn {
        @include flex(center, 0, wrap);
        gap: 40px;
        .c-btn__01 {
            width: calc(50% - 20px);
            max-width: 380px;
            margin: 0;
            @include mq(sm) {
                width: 100%;
            }
            &:nth-child(1) {
                @include mq(sm) {
                    order: 1;
                }
            }
            &:nth-child(2) {
                @include mq(sm-min) {
                    margin-left: auto;
                }
                @include mq(sm) {
                    order: 0;
                }
            }
        }
    }
}

.mw_wp_form_confirm {
	.input-block{
		display: none;
	}
    .contact-page {
        &__ct {
            @include mq {
                padding: 37px 35px;
            }
            @include mq(xs) {
                padding: 15px;
            }
            @include mq(md-min) {
                @include flex(0, center, wrap);
                &._block {
                    display: block;
                }
            }
        }
		&__address{
            width: 100%;
			span{
				display: none;
			}
		}
    }
}

.contact-thanks {
    padding: 45px 0 201px;
    @include mq {
        padding: 37px 0 90px;
    }
    .c-txt__04 {
        @include mq(md-min) {
            letter-spacing: 0.06em;
            margin-bottom: 195px;
        }
        @include mq {
            margin-bottom: 88px;
        }
    }
    .c-btn__01 {
        @include mq(md-min) {
            max-width: 380px;
        }
    }
}

.mw_wp_form .horizontal-item + .horizontal-item{
    margin-left: 0 !important;
}