@use "vars" as *;

.sec-article{
    padding: 95px 0 100px;
    @include mq(){
        padding: 39px 0 205px;
    }
}
.article-slider{
    @include flex(between,0,wrap);
    margin-bottom: 39px;
    @include mq(){
        margin: 0 -25px 8px;
    }
    &__lt{
        width: 63.5%;
        @include mq(){
            width: 100%;
            padding: 0 13px;
        }
    }
    &__rt{
        width: 31.4%;
        @include mq(){
            width: 100%;
        }
    }
}
.list-for {	
	&__item {
		position: relative;
		overflow: hidden;		
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(525 / 700 * 100%);
		}			
	}
    &__thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: "object-fit: cover";
		}
    }
    .slick-arrow{
        width: 40px;
        height: 60px;
        transition: 0.3s ease;
        @include p-before(40px,60px){
            background: url('../img/gallery/article/icon-arr.png') no-repeat center/cover;
            top:0;
            left: 0;
            transition: 0.3s ease;
            @include mq(){
                width: 30px;
                height: 45px;
            }
        }
        &.slick-prev{
            left: 0;
            z-index: 2;
            transform: translateY(-50%) rotate(-180deg);
            @include mq(){
                left: 15px;
                top: 43%;
            }
        }
        &.slick-next{
            right: 0;
            transform: translateY(-50%);
            @include mq(){
                right: 15px;
                top: 49%;
            }
        }
        &:hover{
            transition: 0.3s ease;
            &::before{
                transition: 0.3s ease;
            }
        }
    }
}
.list-nav {	
    background: #f6f1e8;
    padding: 21px 8px 0;
    @include mq(){
        padding: 8px 0 8px 20px;
    }
	.slick-track {
        @include mq(md-min){
            display: flex;
            flex-wrap: wrap;
            width: 100% !important;
            transform: unset !important;
            max-height: clamp(340px, 40.718vw, 505px);
            overflow-y: auto;
            scrollbar-width: auto;
            scrollbar-color: #ccc #F3F3F3;
            &::-webkit-scrollbar {
                width: 10px;
                height: 6px;
            }        
            &::-webkit-scrollbar-track {
                background: #F3F3F3;
            }        
            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border-radius: 10px;
                border: 1px solid #F3F3F3;
            }
        }
	}	
	.slick-cloned {
        @include mq(md-min){
            display: none;
        }
	}
	&__item {
        @include mq(md-min){
            padding: 0 9px;
            width: 50% !important;
            margin-bottom: 16px;
        }
        @include mq(){
            padding: 0 5px;
        }
		&.slick-active{
            .list-nav__img{
                &-thumbnail{
                    //border: 8px #ebb663 solid;
                    transition: 0.3s ease;
                    @include mq(){
                        border: none;
                    }
                }
                &:hover{
                    opacity: 1;
                    cursor: default;
                }
            }
        }
	}	
	&__img {
        position: relative;
        overflow: hidden;
        transition: 0.3s ease;
		&:hover {
			cursor: pointer;
            opacity: 0.7;
            transition: 0.3s ease;
		}
		&:before {
            content: "";
            display: block;
            padding-bottom: calc(110 / 147 * 100%);
            @include mq(){
                padding-bottom: 70.566474%;
            }
		}		
		&-thumbnail {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: 0.3s ease;
		}		
		img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: "object-fit: cover";
		}
	}
    .slick-arrow{
        transition: 0.3s ease;
        &.slick-prev{
            display: none !important;
        }
        &.slick-next{
            width: 100%;
            height: 40px;
            background: rgba($color: #53432e, $alpha: 0.68);
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translate(0);
            @include p-before(25px,15px){
                background: url('../img/gallery/article/icon-arr02.png') no-repeat center/cover;
                @include v-align();
                transform: translate(-50%, -45%);
            }
            @include mq(){
                display: none !important;
            }
        }
        &:hover{
            opacity: 0.7;
            transition: 0.3s ease;
        }
    }
}
.article-box{
    @include flex(0,start,wrap);
    margin-bottom: 56px;
    @include mq(){
        margin-bottom: 5px;
    }
    &__lt{
        width: 50%;
        @include flex(0,0,wrap);
        @include mq(){
            width: 100%;
            padding: 0 6%;
        }
    }
    &__rt{
        width: 50%;
        padding-top: 23px;
        color: #0a0204;
        @include mq(){
            width: 100%;
            order: -1;
            margin-bottom: 41px;
        }
    }
    &__item{
        width: 46%;
        padding-right: 30px;
        position: relative;
        @include mq(){
            width: 100%;
            padding-right: 0;
            margin-bottom: 43px;
        }
        @include p-before(0px,0px){
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 10px solid #aeaeae;
            @include v-align();
            right: 10px;
            @include mq(){
                border-top: 40px solid transparent;
                border-bottom: 40px solid transparent;
                border-left: 20px solid #aeaeae;
                right: 47%;
                top: auto;
                bottom: -75px;
                transform: rotate(90deg);
            }
        }
        &-ttl{
            text-align: left;
            letter-spacing: 0.1rem;
            @include mq(){
                @include font-size(27.5);
            }
        }
        &-img{
            position: relative;
            overflow: hidden;
            border: 20px #f0f0f0 solid;
            border-width: 20px 28px;
            @include mq(){
                border-width: 26px 36px;
            }
            &:before {
                content: "";
                display: block;
                padding-bottom: calc(126 / 168 * 100%);
            }
            picture{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--02{
            width: 54%;
            @include mq(){
                width: 100%;
                margin-bottom: 37px;
            }
            &:before{
                display: none;
            }
            .article-box__item-img{
                border-color: #f6f1e8;
                border-width: 24px 30px;
                @include mq(){
                    border-width: 26px 36px;
                }
                &:before{
                    padding-bottom: calc(154 / 205 * 100%);
                }
            }
        }
    }
}
.article-table{
    width: 100%;
    color: #0a0204;
    margin-bottom: 152px;
    @include mq(){
        @include font-size(15);
        margin-bottom: 87px;
    }
    tbody{
        @include mq(){
            display: block;
        }
    }
    tr{
        @include mq(){
            width: 100%;
            display: block;
        }
        th{
            background: #f7f5f1;
            padding: 9px 20px;
            border: 1px #ad9877 solid;
            width: 250px;
            vertical-align: top;
            font-weight: 300;
            @include mq(){
                width: 100%;
                display: block;
                border-bottom: none;
                padding: 16px 15px;
            }
        }
        td{
            padding: 9px 19px;
            border: 1px #ad9877 solid;
            @include mq(){
                width: 100%;
                display: block;
                border-bottom: none;
                padding: 16px 15px;
            }
        }
        &:last-child{
            td{
                @include mq(){
                    border-bottom: 1px #ad9877 solid;
                }
            }
        }
    }
    &--02{
        margin-bottom: 43px;
        tr{
            th{
                @include mq(md-min){
                    padding: 10px 20px;
                }
            }
            td{
                padding: 10px 19px;
                letter-spacing: 0.1rem;
                @include mq(){
                    line-height: 1.6;
                    padding: 17px;
                    letter-spacing: 0;
                }
            }
        }
    }
    &--03{
        margin-bottom: 23px;
        @include mq(){
            margin-bottom: 42px;
        }
        tr{
            th{
                @include mq(md-min){
                    padding: 10px 20px;
                    width: 199px;
                }
            }
            td{
                padding: 10px 26px;
                letter-spacing: 0.1rem;
                @include mq(){
                    line-height: 1.6;
                    padding: 16px 17px 15px;
                    letter-spacing: 0;
                }
            }
        }
    }
}