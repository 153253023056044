@use "vars" as *;

.article-page {
    padding: 89px 0 100px;
    @include mq {
        padding: 31px 0 89px;
    }
    &__date {
        @include fts(16,34);
        font-weight: bold;
        color: #937a56;
        margin-bottom: 1px;
        @include mq {
            @include font-size(15);
        }
    }
    &__ttl {
        @include fts(24,34);
        font-weight: bold;
        color: #937a56;
        border-bottom: 2px solid #b19c7a;
        position: relative;
        padding-bottom: 15px;
        @include mq {
            @include fts(18,28);
            border-bottom-width: 1px;
            padding-bottom: 10px;
        }
        @include mq(xs) {
            @include font-size(16);
        }
        &::before,
        &::after {
            position: absolute;
            content: '';
            height: 10px;
            width: 160px;
            top: 100%;
            @include mq {
                height: 5px;
                width: 80px;
            }
        }
        &::before {
            left: 0;
            background: #ebe0ce;
        }
        &::after {
            left: 160px;
            background: #b29d7c;
            @include mq {
                left: 80px;
            }
        }
    }
    &__tags {
        @include flex(0,0,wrap);
        @include font-size(10);
        line-height: 1.4;
        margin-bottom: 10px;
        gap: 10px;
        @include font-size(10);
        font-weight: bold;
        font-family: $font-gothic;
        @include mq {
            gap: 5px;
            margin-bottom: 5px;
        }
        > * {
            border: 1px solid #5e4a2e;
            color: #5e4a2e;
            padding: 6px 9px 5px;
        }
        &--fullColor {
            > * {
                background: #5e4a2e;
                color: white;
            }
        }
        &--brown{
            @include font-size(10);
            a{
                background: #665845;
                color: #fff;
            }
        }
        &--02{
            @include font-size(10);
            a{
                color: #665845;
            }
        }
    }
    &__ct {
        margin-top: 24px;
        margin-bottom: 165px;
        color: #0a0204;
        @include mq {
            margin: 40px -12px 73px;
            > * {
                &:not(img) {
                    padding: 0 12px;
                }
            }
        }
        img {
            display: block;
            width: 100%;
            margin-bottom: 62px;
            @include mq {
                margin-bottom: 32px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__action {
        @include flex(0,0,wrap);
        font-family: $font-gothic;
        font-weight: normal;
        &-center {
            width: 100%;
            margin-bottom: -32px;
            order: 0;
            @include mq(sm) {
                margin-bottom: 20px;
            }
        }
        .c-btn__02 {
            &--arrLeft {
                order: 1;
                margin-right: auto;
            }
            &--arrRight {
                order: 2;
                margin-left: auto;
            }
        }
    }
}
