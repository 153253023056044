@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
@media screen and (min-width: 835px) {
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
HTML
==================================================== */
html {
  font-size: 62.5%;
}

html.ov {
  overflow: hidden;
}

body {
  color: #0a0204;
  font-family: "Noto Serif JP", serif;
  font-weight: 300;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
  background: #fff;
  overflow-x: hidden;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  outline: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}
@media screen and (max-width: 834px) {
  a {
    -webkit-tap-highlight-color: transparent;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ====================================================
Layout
==================================================== */
.inner {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
}
@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    padding: 0 12px;
  }
}
.inner--1265 {
  max-width: 1330px;
  width: 100%;
  padding: 0 30px;
}
.inner--1278 {
  max-width: 1338px;
  width: 100%;
  padding: 0 30px;
}
@media screen and (max-width: 834px) {
  .inner--1278 {
    padding: 0 8px;
  }
}

.mv-page__ttl {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 15px;
  padding: 35px 15px 38px;
  top: 58%;
  transform: translateY(-50%);
  background: url(../img/blog/ttl-ico.png) top center no-repeat;
  background-size: 154px auto;
  font-family: "Castellar";
  font-size: 3.8rem;
  text-align: center;
  color: #ab9572;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 834px) {
  .mv-page__ttl {
    font-size: 3rem;
    background-size: 123px auto;
    padding: 26px 15px 38px;
    top: 62.5%;
  }
}
.mv-page__img img {
  display: block;
  width: 100%;
  max-height: 416px;
  min-height: 300px;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center", sans-serif;
}
@media screen and (max-width: 834px) {
  .mv-page__img img {
    max-height: 300px;
    min-height: 200px;
  }
}

.breadcrumb {
  background: #ededed;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.2rem;
  padding: 9px 0;
  font-weight: 400;
}
@media screen and (max-width: 834px) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb .inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 2px;
}
.breadcrumb a {
  color: #937a56;
  text-decoration: underline;
  text-underline-position: under;
}

.form-control {
  display: block;
  width: 100%;
  height: 42px;
  background: white;
  border: 1px solid #c9b99f;
  font-weight: 500;
  padding: 5px 16px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  outline: none;
  appearance: none;
  resize: none;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .form-control {
    height: 55px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1;
  }
}
.form-control::placeholder {
  color: #acacac;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #acacac;
}

textarea.form-control {
  height: 200px;
  padding-top: 10px;
}
@media screen and (max-width: 834px) {
  textarea.form-control {
    line-height: 1.7142857143;
    height: 255px;
    padding-top: 15px;
  }
}

.group-select {
  position: relative;
  z-index: 1;
}
.group-select::after {
  content: "";
  position: absolute;
  top: 48%;
  right: 29px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg) translateY(-50%);
  border-top: 2px solid #937a56;
  border-right: 2px solid #937a56;
  pointer-events: none;
}

.pure-input,
.mwform-checkbox-field label,
.mwform-radio-field label {
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4375;
}
@media screen and (max-width: 834px) {
  .pure-input,
.mwform-checkbox-field label,
.mwform-radio-field label {
    font-size: 1.5rem;
  }
}
.pure-input input,
.mwform-checkbox-field label input,
.mwform-radio-field label input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.pure-input input[type=checkbox] + span::before,
.mwform-checkbox-field label input[type=checkbox] + span::before,
.mwform-radio-field label input[type=checkbox] + span::before {
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
}
.pure-input input[type=checkbox] + span:after,
.mwform-checkbox-field label input[type=checkbox] + span:after,
.mwform-radio-field label input[type=checkbox] + span:after {
  background-color: transparent;
  top: 3px;
  left: 4px;
  width: 15px;
  height: 8px;
  border-style: solid;
  border-width: 0 0 2px 2px;
  border-color: white;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.pure-input input[type=checkbox]:checked + span::before,
.mwform-checkbox-field label input[type=checkbox]:checked + span::before,
.mwform-radio-field label input[type=checkbox]:checked + span::before {
  background: #937a56;
}
.pure-input input[type=checkbox]:checked + span:after,
.mwform-checkbox-field label input[type=checkbox]:checked + span:after,
.mwform-radio-field label input[type=checkbox]:checked + span:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.pure-input input[type=radio] + span:after, .pure-input input[type=radio] + span:before,
.mwform-checkbox-field label input[type=radio] + span:after,
.mwform-checkbox-field label input[type=radio] + span:before,
.mwform-radio-field label input[type=radio] + span:after,
.mwform-radio-field label input[type=radio] + span:before {
  border-radius: 50%;
}
.pure-input input[type=radio]:checked + span:after,
.mwform-checkbox-field label input[type=radio]:checked + span:after,
.mwform-radio-field label input[type=radio]:checked + span:after {
  transform: scale(1);
}
.pure-input > span,
.mwform-checkbox-field label > span,
.mwform-radio-field label > span {
  display: block;
  position: relative;
  padding: 0 0 0 27px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.pure-input > span:before,
.mwform-checkbox-field label > span:before,
.mwform-radio-field label > span:before {
  box-sizing: content-box;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  border: 1px solid #937a56;
  background: white;
  text-align: center;
  transition: all 0.4s ease;
  border-radius: 4px;
}
.pure-input > span:after,
.mwform-checkbox-field label > span:after,
.mwform-radio-field label > span:after {
  box-sizing: content-box;
  content: "";
  background-color: #937a56;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

/* ====================================================
Control
==================================================== */
.c-fc--ff {
  color: #fff;
}

.c-ft--en {
  font-family: "Castellar";
}
.c-ft--base {
  font-family: "Noto Serif JP", serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-val--md {
  vertical-align: middle;
}
.c-val--top {
  vertical-align: top;
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.7647058824;
}
@media screen and (max-width: 1200px) {
  .c-txt__01 {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.7333333333;
  }
}
@media screen and (max-width: 834px) {
  .c-txt__01 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7857142857;
  }
}
.c-txt__02 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7007142857;
}
@media screen and (max-width: 834px) {
  .c-txt__02 {
    line-height: 1.5714285714;
  }
}
.c-txt__03 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}
@media screen and (max-width: 834px) {
  .c-txt__03 {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.6666666667;
  }
}
.c-txt__04 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5555555556;
}
@media screen and (max-width: 834px) {
  .c-txt__04 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7857142857;
  }
}

.c-ttl__01 {
  text-align: center;
  color: #ab9572;
  padding-top: 35px;
  letter-spacing: 3px;
  margin-bottom: 40px;
  font-weight: 400;
  position: relative;
  font-family: "Castellar";
  font-size: 3.8rem;
}
@media screen and (max-width: 834px) {
  .c-ttl__01 {
    letter-spacing: 2.5px;
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 2.65rem;
  }
}
.c-ttl__01:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 154px;
  height: 33px;
  background: url("../img/index/icon-ttl.png") center no-repeat;
  background-size: contain;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .c-ttl__01:before {
    width: 109px;
    height: 24px;
  }
}
.c-ttl__01--white {
  color: #fff;
}
.c-ttl__01--white::before {
  background: url("../img/index/icon-ttl-white.png") center no-repeat;
  background-size: contain;
}
.c-ttl__02 {
  font-size: 3.795rem;
  text-align: center;
  background: url(../img/contact/ico-ttl.png) bottom center no-repeat;
  color: #ad9877;
  padding-bottom: 21px;
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .c-ttl__02 {
    font-size: 2.9865rem;
    background-size: 30px;
    padding-bottom: 16px;
  }
}
.c-ttl__02--brown {
  color: #937a56;
}
.c-ttl__03 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4166666667;
  font-weight: bold;
  text-align: center;
  color: #937a56;
}
@media screen and (max-width: 834px) {
  .c-ttl__03 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5555555556;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__03 {
    font-size: 1.6rem;
  }
}
.c-ttl__03--grey {
  color: #999187;
}
.c-ttl__03--border {
  border-bottom: 2px solid #b19c7a;
  position: relative;
  text-align: left;
  padding-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--border {
    border-bottom-width: 1px;
    padding-bottom: 10px;
  }
}
.c-ttl__03--border::before, .c-ttl__03--border::after {
  position: absolute;
  content: "";
  height: 10px;
  width: 160px;
  top: 100%;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--border::before, .c-ttl__03--border::after {
    height: 5px;
    width: 80px;
  }
}
.c-ttl__03--border::before {
  left: 0;
  background: #ebe0ce;
}
.c-ttl__03--border::after {
  left: 160px;
  background: #b29d7c;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--border::after {
    left: 80px;
  }
}

.c-btn__01 {
  border-radius: 6px;
  width: 100%;
  max-width: 270px;
  height: 66px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background: url("../img/index/bg-btn.png") center no-repeat;
  background-size: cover;
  color: #fff;
  position: relative;
  font-weight: 500;
  font-size: 2.2rem;
  border: none;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    font-size: 1.87rem;
    height: 56px;
  }
}
@media screen and (max-width: 640px) {
  .c-btn__01 {
    max-width: 100%;
    border-radius: 3px;
  }
}
.c-btn__01:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 31px;
  height: 59px;
  background: url("../img/index/icon-before.png") center no-repeat;
  background-size: contain;
  left: 2px;
  top: 4px;
}
@media screen and (max-width: 834px) {
  .c-btn__01:before {
    left: 3px;
    top: 3px;
    width: 28px;
    height: 49px;
  }
}
.c-btn__01:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 31px;
  height: 59px;
  background: url("../img/index/icon-after.png") center no-repeat;
  background-size: contain;
  right: 2px;
  top: 4px;
}
@media screen and (max-width: 834px) {
  .c-btn__01:after {
    width: 28px;
    height: 49px;
    right: 3px;
    top: 3px;
  }
}
@media screen and (min-width: 835px) {
  .c-btn__01:hover {
    opacity: 0.7;
  }
}
.c-btn__01 small {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .c-btn__01 small {
    font-size: 1.25rem;
  }
}
.c-btn__01--white {
  background: url("../img/index/bg-btn-white.png") center no-repeat;
  background-size: cover;
  color: #706048;
}
.c-btn__01--white::before {
  background: url("../img/index/icon-after02.png") center no-repeat;
  background-size: contain;
}
.c-btn__01--white::after {
  background: url("../img/index/icon-before02.png") center no-repeat;
  background-size: contain;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    max-width: 380px;
  }
}
.c-btn__01.isDisable {
  pointer-events: none;
}
.c-btn__01--short {
  max-width: 270px;
}
@media screen and (max-width: 834px) {
  .c-btn__01--short {
    max-width: 350px;
  }
}
.c-btn__02 {
  font-size: 1.4rem;
  color: #ad9877;
  border: 1px solid #ad9877;
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 6px 5px;
  width: 150px;
}
.c-btn__02 .arr {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #ad9877;
  width: 31px;
}
.c-btn__02 .arr::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  inset: 0;
  margin: auto;
}
.c-btn__02--arrLeft, .c-btn__02--arrRight {
  width: 132px;
}
.c-btn__02--arrLeft {
  padding-left: 32px;
}
.c-btn__02--arrLeft .arr {
  left: 0;
}
.c-btn__02--arrLeft .arr::before {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-color: white;
}
.c-btn__02--arrRight {
  padding-right: 32px;
}
.c-btn__02--arrRight .arr {
  right: 0;
}
.c-btn__02--arrRight .arr::before {
  border-top: 1px solid;
  border-right: 1px solid;
  border-color: white;
}

/* ====================================================
Utility
==================================================== */
@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media (any-hover: hover) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.u-img-of {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center", sans-serif;
}

.c-scroll-box {
  padding-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.c-scroll-box-y {
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media screen and (max-width: 834px) {
  .c-scroll-box-sp {
    padding-bottom: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.js-u__scrollin {
  opacity: 0;
  -webkit-transform: translate(0, 70px);
  -ms-transform: translate(0, 70px);
  transform: translate(0, 70px);
}

.js-u__scrollin.is-visible {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* ====================================================
Header
==================================================== */
.main-header__top {
  display: none;
}
@media screen and (max-width: 834px) {
  .main-header__top {
    display: block;
    background: #c9b698;
    padding: 9px 15px;
    text-align: center;
  }
  .main-header__top img {
    width: 44px;
  }
}
.main-header__inner {
  top: 24px;
  left: 23px;
  width: 100%;
  max-width: 222px;
  padding: 40px 4px 30px 8px;
  background-color: rgba(175, 149, 108, 0.851);
  z-index: 99;
  position: fixed;
}
@media screen and (max-width: 1390px) {
  .main-header__inner {
    padding: 20px 3px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__inner {
    display: none;
    right: 0;
    padding: 13px 5px;
    left: auto;
    top: 70px;
    max-width: 261px;
  }
}
.main-header__ct {
  max-height: 80vh;
  overflow-y: auto;
}
@media screen and (max-width: 834px) {
  .main-header__ct {
    height: 70vh;
  }
}
.main-header__logo {
  text-align: center;
  margin-bottom: 34px;
  width: 100%;
}
@media screen and (max-width: 1390px) {
  .main-header__logo {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__logo {
    padding: 0 15px;
    margin-bottom: 11px;
    text-align: left;
  }
}
.main-header__logo img {
  width: 105px;
  display: inline-block;
}
@media screen and (max-width: 1390px) {
  .main-header__logo img {
    width: 80px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__logo img {
    width: 44px;
  }
}
.main-header__navGlobal {
  width: 100%;
}
.main-header__menu {
  width: 100%;
}
.main-header__menu-item {
  border-bottom: 1px solid #fff;
}
.main-header__menu-item:first-child {
  border-top: 1px solid #fff;
}
.main-header__menu-item a {
  color: #fff;
  display: block;
  padding: 15px 22px 12.5px;
  font-weight: 700;
  font-size: 1.8rem;
}
@media screen and (max-width: 1390px) {
  .main-header__menu-item a {
    padding: 10px 15px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__menu-item a {
    padding: 13px 28px 12.5px;
    font-size: 1.7rem;
  }
}
.main-header__contact {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 29px 0 22px;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 1390px) {
  .main-header__contact {
    padding: 15px 0;
  }
}
@media screen and (max-width: 834px) {
  .main-header__contact {
    padding: 26px 17px 23px;
    margin-bottom: 7px;
    justify-content: flex-end;
  }
}
.main-header__contact-item {
  padding: 0 10px;
}
@media screen and (max-width: 834px) {
  .main-header__contact-item {
    padding: 0 9px;
  }
}
.main-header__contact-item a {
  display: block;
}
.main-header__contact-item a img {
  width: 37px;
}
@media screen and (max-width: 834px) {
  .main-header__contact-item a img {
    width: 35px;
  }
}
.main-header__tel {
  padding: 20px 10px 17px;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 834px) {
  .main-header__tel {
    display: none;
  }
}
.main-header__tel a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  letter-spacing: 1.65px;
  padding-top: 1px;
  font-family: "Castellar";
  font-size: 1.9rem;
}
.main-header__tel span {
  margin-right: 7px;
}
.main-header__tel span img {
  width: 28px;
}
.main-header__address {
  padding: 32px 17px 25px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 300;
  line-height: 1.428;
  font-size: 1.4rem;
}
@media screen and (max-width: 1390px) {
  .main-header__address {
    padding: 15px 10px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__address {
    display: none;
  }
}
.main-header__privacy {
  font-size: 1.4rem;
  color: #fff;
  display: block;
  font-weight: 300;
  text-align: center;
  padding: 5px 14px 6px 10px;
  letter-spacing: 1.5px;
  border: 1px solid #fff;
  max-width: 206px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.main-header__privacy:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
  top: 47%;
  right: 13px;
}
@media screen and (max-width: 834px) {
  .main-header__privacy {
    margin-bottom: 14px;
    padding: 6px 14px 6px 10px;
    max-width: 250px;
    font-size: 1.3rem;
  }
}
.main-header__close {
  width: 30px;
  position: absolute;
  bottom: -30px;
  left: 0;
  cursor: pointer;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .main-header__close {
    position: static;
    width: 100%;
    text-align: center;
  }
  .main-header__close img {
    width: 24px;
  }
}
.main-header__close:hover {
  transition: all 0.3s;
  opacity: 0.7;
}
.main-header__mobile {
  display: none;
  position: fixed;
  z-index: 98;
  left: 0;
  top: 146px;
  width: 102px;
  cursor: pointer;
  transition: all 0.3s;
}
.main-header__mobile:hover {
  transition: all 0.3s;
  opacity: 0.7;
}
@media screen and (max-width: 1200px) {
  .main-header__mobile {
    width: 80px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__mobile {
    display: none;
  }
}
.main-header__mobile img {
  width: 100%;
}
.main-header__mobile.on {
  display: block;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .main-header__mobile.on {
    display: none !important;
  }
}

.contact-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  z-index: 99;
  background-color: rgba(130, 104, 64, 0.749);
  padding: 7px 9px 3px;
}
@media screen and (max-width: 834px) {
  .contact-fixed {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.contact-fixed__menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 95px);
  padding-right: 4px;
}
.contact-fixed__menu-item {
  margin-right: 4px;
  width: calc(50% - 2px);
}
.contact-fixed__menu-item:nth-child(2) {
  margin-right: 0;
}
.contact-fixed__menu-item a {
  min-height: 45px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 3px;
  background: #fff;
  text-align: center;
  padding: 3px 4px 4px;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: bold;
  color: #af956c;
  font-size: 1.05rem;
}
.contact-fixed__menu-item a span {
  display: block;
  margin-bottom: 5px;
  width: 100%;
}
.contact-fixed__menu-item a span picture, .contact-fixed__menu-item a span img {
  max-width: 21px;
  max-height: 19px;
}
.contact-fixed__mobile {
  width: 95px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.contact-fixed__mobile-item {
  margin-right: 4px;
  width: calc(50% - 2px);
}
.contact-fixed__mobile-item:nth-child(2) {
  margin-right: 0;
}

/* ====================================================
Footer
==================================================== */
.sec-refrom {
  padding: 170px 0 197px;
  background: url("../img/common/bg-ft.png") center no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1200px) {
  .sec-refrom {
    padding: 120px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-refrom {
    padding: 33px 0 43px;
    background: url("../img/common/bg-ft-sp.png") center no-repeat;
    background-size: cover;
  }
}

.refrom-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .refrom-flex {
    flex-wrap: wrap;
  }
}
.refrom-flex__ttl {
  width: 50%;
  font-weight: 500;
  color: #fff;
  padding-top: 20px;
  line-height: 1.4;
  font-size: 3rem;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__ttl {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__ttl {
    padding-top: 0;
    margin-bottom: 71px;
    width: 100%;
    text-align: center;
    line-height: 1.556;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .refrom-flex__ttl {
    font-size: 1.6rem;
  }
}
.refrom-flex__ttl span {
  display: block;
  font-weight: 900;
  color: #ffe859;
  margin-bottom: 22px;
  font-size: 2rem;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__ttl span {
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__ttl span {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
}
.refrom-flex__ct {
  width: 50%;
  padding-left: 81px;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__ct {
    padding-left: 35px;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__ct {
    width: 100%;
    padding-left: 0;
  }
}
.refrom-flex__tel {
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .refrom-flex__tel {
    margin-bottom: 7px;
  }
}
.refrom-flex__tel a {
  font-weight: 500;
  color: #fff;
  line-height: 1;
  letter-spacing: 6px;
  font-size: 5.8rem;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__tel a {
    letter-spacing: 2px;
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__tel a {
    letter-spacing: 4px;
    padding-left: 5px;
    text-align: center;
    display: block;
    font-size: 4.25rem;
  }
}
@media screen and (max-width: 374px) {
  .refrom-flex__tel a {
    padding-left: 0;
    letter-spacing: 0;
    font-size: 3.8rem;
  }
}
.refrom-flex__tel a span {
  letter-spacing: 0;
  font-weight: 300;
  margin-right: 7px;
  font-size: 3.6rem;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__tel a span {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__tel a span {
    font-size: 2.6rem;
  }
}
.refrom-flex__note {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 38px;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__note {
    margin-bottom: 25px;
    justify-content: space-between;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__note {
    margin-bottom: 31px;
    justify-content: center;
  }
}
.refrom-flex__note p {
  font-weight: 500;
  color: #ffe859;
  font-size: 2rem;
}
.refrom-flex__note p:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__note p:not(:last-child) {
    margin-right: 20px;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__note p:not(:last-child) {
    margin-right: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .refrom-flex__note p {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__note p {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .refrom-flex__note p {
    font-size: 1.2rem;
  }
}
.refrom-flex__note p span {
  color: #fff;
  margin-left: 7px;
  font-size: 2.4rem;
}
@media screen and (max-width: 1200px) {
  .refrom-flex__note p span {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .refrom-flex__note p span {
    margin-left: 4px;
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 374px) {
  .refrom-flex__note p span {
    font-size: 1.5rem;
  }
}
.refrom-flex__note p b {
  color: #fff;
  margin-left: 7px;
}
.refrom-flex__email {
  color: #fff;
  font-size: 2.3rem;
  border-radius: 3px;
  max-width: 460px;
  width: 100%;
  padding: 14px;
  position: relative;
  border: 1px solid #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.18);
}
@media screen and (max-width: 834px) {
  .refrom-flex__email {
    max-width: 380px;
    margin: 0 auto;
    padding: 17px 10px 14px;
    font-size: 1.9rem;
  }
}
.refrom-flex__email span {
  width: 30px;
  margin-right: 10px;
}
@media screen and (max-width: 834px) {
  .refrom-flex__email span {
    width: 26px;
    margin-right: 6px;
  }
}
.refrom-flex__email span img {
  width: 100%;
}
.refrom-flex__email:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 31px;
  height: 51px;
  background: url("../img/index/icon-before03.png") center no-repeat;
  background-size: contain;
  left: 4px;
  top: 3px;
}
@media screen and (max-width: 834px) {
  .refrom-flex__email:before {
    left: 2px;
    height: 49px;
  }
}
.refrom-flex__email:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 31px;
  height: 51px;
  background: url("../img/index/icon-after03.png") center no-repeat;
  background-size: contain;
  right: 4px;
  top: 3px;
}
@media screen and (max-width: 834px) {
  .refrom-flex__email:after {
    right: 2px;
    height: 49px;
  }
}

.main-footer__content {
  padding: 65px 0 93px;
  background: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .main-footer__content {
    padding: 46px 0 37px;
  }
}
.main-footer__clmL {
  width: 180px;
}
@media screen and (max-width: 834px) {
  .main-footer__clmL {
    width: 100%;
    text-align: center;
  }
}
.main-footer__logo {
  text-align: center;
  margin-bottom: 23px;
  padding-right: 10px;
  padding-top: 3px;
}
@media screen and (max-width: 834px) {
  .main-footer__logo {
    margin-bottom: 32px;
    padding-right: 0;
  }
}
.main-footer__logo picture, .main-footer__logo img {
  width: 80px;
}
@media screen and (max-width: 834px) {
  .main-footer__logo picture, .main-footer__logo img {
    width: 93px;
  }
}
.main-footer__address {
  margin: 20px 0 10px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 500;
  color: #937a56;
}
@media screen and (max-width: 834px) {
  .main-footer__address {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.main-footer__address span {
  margin-bottom: 3px;
  display: block;
  font-size: 2.1rem;
}
@media screen and (max-width: 834px) {
  .main-footer__address span {
    margin-bottom: 5px;
    font-size: 2.5rem;
  }
}
.main-footer__clmR {
  width: calc(100% - 180px);
  max-width: 850px;
  margin-left: auto;
}
@media screen and (max-width: 834px) {
  .main-footer__clmR {
    width: 100%;
    max-width: 100%;
  }
}
.main-footer__nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 833px;
  width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 1200px) {
  .main-footer__nav {
    padding-left: 50px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__nav {
    display: none;
  }
}
.main-footer__menu {
  width: 38%;
  font-size: 1.5rem;
  font-weight: 500;
}
.main-footer__menu:nth-child(2) {
  width: 44%;
}
.main-footer__menu:nth-child(3) {
  width: 18%;
}
@media screen and (max-width: 640px) {
  .main-footer__menu {
    width: 100%;
    text-align: center;
  }
}
.main-footer__menu-item {
  margin-bottom: 22px;
  padding-right: 10px;
}
.main-footer__menu-item a {
  padding-left: 23px;
  position: relative;
  font-weight: 500;
  color: #937a56;
  display: block;
  font-size: 1.4rem;
}
.main-footer__menu-item a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 13px;
  background: url("../img/common/icon-link.png") center no-repeat;
  background-size: contain;
  top: 3px;
  left: 0;
}
.main-footer__copyright {
  font-family: "Castellar";
  font-size: 1.2rem;
  text-align: center;
  font-weight: 300;
  background: #877251;
  padding: 16px;
  color: #fff;
}
@media screen and (max-width: 834px) {
  .main-footer__copyright {
    padding-bottom: 70px;
    font-size: 1.3rem;
  }
}

/* ====================================================
PAGE INDEX
==================================================== */
#page-index.wrapper {
  padding-top: 0 !important;
}

.mv-index {
  position: relative;
}
.mv-index__slider {
  margin-bottom: 0 !important;
}
.mv-index__slider-item {
  box-shadow: none !important;
}
@media screen and (max-width: 834px) {
  .mv-index__slider-item {
    position: relative;
    overflow: hidden;
  }
  .mv-index__slider-item:before {
    display: block;
    content: "";
    padding-top: 80.2666666667%;
  }
  .mv-index__slider-item > :first-child {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mv-index__slider-item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.mv-index__slider .slick-dots {
  bottom: 14px;
}
@media screen and (max-width: 834px) {
  .mv-index__slider .slick-dots {
    display: none !important;
  }
}
.mv-index__slider .slick-dots li {
  margin: 0 5px;
  width: 62px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}
.mv-index__slider .slick-dots li button {
  display: none;
}
.mv-index__slider .slick-dots li.slick-active {
  background: #fff;
}
.mv-index__desc {
  z-index: 10;
  position: absolute;
  font-weight: 700;
  color: #fff;
  bottom: 81px;
  right: 149px;
  font-family: "Noto Serif JP", serif;
  line-height: 1.5428;
  text-shadow: 1px 0 7px rgba(0, 0, 0, 0.1);
  font-size: 2.1rem;
}
@media screen and (max-width: 1200px) {
  .mv-index__desc {
    right: 50px;
  }
}
@media screen and (max-width: 834px) {
  .mv-index__desc {
    right: auto;
    left: 13px;
    bottom: 22px;
    letter-spacing: 0.25px;
    font-size: 1.4rem;
  }
}

.sec-concept {
  padding: 67px 0 80px;
}
@media screen and (max-width: 834px) {
  .sec-concept {
    padding: 41px 0 89px;
  }
}

.concept-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 61px;
}
@media screen and (max-width: 834px) {
  .concept-flex {
    margin-bottom: 39px;
    flex-wrap: wrap;
  }
}
.concept-flex__ct {
  width: 50%;
  padding-top: 23px;
}
@media screen and (max-width: 1200px) {
  .concept-flex__ct {
    padding-top: 0;
  }
}
@media screen and (max-width: 834px) {
  .concept-flex__ct {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 374px) {
  .concept-flex__ct {
    padding: 0;
  }
}
.concept-flex__ct .c-txt__01 {
  color: #484848;
}
.concept-flex__ttl {
  color: #937a56;
  font-weight: bold;
  margin-bottom: 34px;
  font-size: 2.4rem;
}
@media screen and (max-width: 1200px) {
  .concept-flex__ttl {
    margin-bottom: 20px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .concept-flex__ttl {
    margin-bottom: 26px;
    line-height: 1.556;
    font-size: 1.8rem;
  }
}
.concept-flex__img {
  width: 50%;
  padding: 0 37px 0 45px;
}
@media screen and (max-width: 1200px) {
  .concept-flex__img {
    padding-left: 30px;
    padding-right: 0;
  }
}
@media screen and (max-width: 834px) {
  .concept-flex__img {
    width: 100%;
    padding: 0 16px 0 13px;
  }
}
.concept-flex__img picture, .concept-flex__img img {
  width: 100%;
}

.sec-service {
  padding: 70px 0 81px;
  background: url("../img/index/bg-01.png") center no-repeat;
  background-size: cover;
}
@media screen and (max-width: 834px) {
  .sec-service {
    padding: 37px 0 81px;
    background: url("../img/index/bg-01-sp.jpg") top center no-repeat;
    background-size: cover;
  }
}
.sec-service .c-ttl__01 {
  margin-bottom: 21px;
}
@media screen and (max-width: 834px) {
  .sec-service .c-ttl__01 {
    margin-bottom: 26px;
  }
}

.service-block {
  margin-bottom: 54px;
}
@media screen and (max-width: 834px) {
  .service-block {
    margin-bottom: 35px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 374px) {
  .service-block {
    padding: 0;
  }
}
.service-block__ttl {
  text-align: center;
  font-weight: 700;
  color: #937a56;
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .service-block__ttl {
    text-align: left;
    line-height: 1.556;
    font-size: 1.8rem;
  }
}

.service-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 940px;
  width: 100%;
  margin: 0 auto 70px;
  background: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 834px) {
  .service-list {
    flex-wrap: wrap;
    padding-right: 0;
    margin: 0 -12px 43px;
    width: calc(100% + 24px);
  }
}
.service-list__item {
  width: 33.33%;
}
@media screen and (max-width: 834px) {
  .service-list__item {
    width: 100%;
  }
}
.service-list__item:nth-child(2n) .service-list__img {
  order: 2;
}
.service-list__item:nth-child(2n) .service-list__ct {
  order: 1;
}
.service-list__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .service-list__flex {
    align-items: center;
  }
}
@media screen and (max-width: 640px) {
  .service-list__flex {
    align-items: flex-start;
  }
}
.service-list__ct {
  width: 100%;
  position: relative;
}
@media screen and (max-width: 834px) {
  .service-list__ct {
    width: 50%;
  }
}
.service-list__img {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.service-list__img:before {
  display: block;
  content: "";
  padding-top: 99.7267759563%;
}
.service-list__img > :first-child {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service-list__children {
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: rgba(67, 56, 42, 0.451);
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  border: 1px solid #fff;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  padding: 33px 0;
}
@media screen and (max-width: 1200px) {
  .service-list__children {
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}
@media screen and (max-width: 834px) {
  .service-list__children {
    padding: 18px 0 56px;
    top: 17px;
    left: 18px;
    align-items: center;
    align-content: center;
    width: calc(100% - 36px);
    height: calc(100% - 34px);
  }
}
.service-list__children p {
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 1.9rem;
}
@media screen and (max-width: 1200px) {
  .service-list__children p {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 834px) {
  .service-list__children p {
    line-height: 1.32;
    font-size: 1.15rem;
  }
}
.service-list__ttl {
  font-size: 3rem;
  color: #fff;
  letter-spacing: 2px;
  width: 100%;
  text-shadow: rgb(142, 130, 114) 3px 0px 0px, rgb(142, 130, 114) 2.83487px 0.981584px 0px, rgb(142, 130, 114) 2.35766px 1.85511px 0px, rgb(142, 130, 114) 1.62091px 2.52441px 0px, rgb(142, 130, 114) 0.705713px 2.91581px 0px, rgb(142, 130, 114) -0.287171px 2.98622px 0px, rgb(142, 130, 114) -1.24844px 2.72789px 0px, rgb(142, 130, 114) -2.07227px 2.16926px 0px, rgb(142, 130, 114) -2.66798px 1.37182px 0px, rgb(142, 130, 114) -2.96998px 0.42336px 0px, rgb(142, 130, 114) -2.94502px -0.571704px 0px, rgb(142, 130, 114) -2.59586px -1.50383px 0px, rgb(142, 130, 114) -1.96093px -2.27041px 0px, rgb(142, 130, 114) -1.11013px -2.78704px 0px, rgb(142, 130, 114) -0.137119px -2.99686px 0px, rgb(142, 130, 114) 0.850987px -2.87677px 0px, rgb(142, 130, 114) 1.74541px -2.43999px 0px, rgb(142, 130, 114) 2.44769px -1.73459px 0px, rgb(142, 130, 114) 2.88051px -0.838247px 0px;
  font-weight: 400;
  margin-bottom: 27px;
}
@media screen and (max-width: 1200px) {
  .service-list__ttl {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 834px) {
  .service-list__ttl {
    letter-spacing: 1px;
    font-size: 1.8rem;
  }
}
.service-list__desc {
  padding: 35px 22px;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.6;
}
@media screen and (max-width: 1200px) {
  .service-list__desc {
    padding: 30px 20px;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 834px) {
  .service-list__desc {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .service-list__desc {
    padding: 17px 14px;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.8181818182;
  }
}

.sec-gallery {
  padding: 55px 0 68px;
  background: url("../img/index/bg-02.jpg") center no-repeat;
  background-size: cover;
}
@media screen and (max-width: 834px) {
  .sec-gallery {
    padding: 36px 0 79px;
    background: url("../img/index/bg-02-sp.jpg") center no-repeat;
    background-size: cover;
  }
}
.sec-gallery .c-ttl__01 {
  margin-bottom: 54px;
}
@media screen and (max-width: 834px) {
  .sec-gallery .c-ttl__01 {
    margin-bottom: 32px;
  }
}

.gallery-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 57px;
}
@media screen and (max-width: 834px) {
  .gallery-list {
    margin-bottom: 39px;
  }
}
.gallery-list__item {
  width: 25%;
}
@media screen and (max-width: 834px) {
  .gallery-list__item {
    width: 50%;
  }
}
.gallery-list__item a {
  display: block;
  position: relative;
}
@media screen and (min-width: 835px) {
  .gallery-list__item a:hover {
    opacity: 1;
  }
  .gallery-list__item a:hover .gallery-list__ct {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.5s;
  }
}
.gallery-list__img {
  position: relative;
  overflow: hidden;
}
.gallery-list__img:before {
  display: block;
  content: "";
  padding-top: 68%;
}
.gallery-list__img > :first-child {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-list__ct {
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.439);
  border: 4px solid #fff;
  color: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media screen and (max-width: 834px) {
  .gallery-list__ct {
    pointer-events: auto;
    opacity: 1;
    border-width: 2px;
  }
}
.gallery-list__ct p {
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  z-index: 10;
  margin-bottom: 54px;
  font-size: 1.8rem;
}
@media screen and (max-width: 1200px) {
  .gallery-list__ct p {
    margin-bottom: 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .gallery-list__ct p {
    font-size: 1rem;
  }
}
.gallery-list__ct p span {
  display: block;
  border: 1px solid #fff;
  padding: 6px 15px;
  margin-top: 30px;
}
@media screen and (max-width: 1200px) {
  .gallery-list__ct p span {
    margin-top: 15px;
  }
}
@media screen and (max-width: 834px) {
  .gallery-list__ct p span {
    padding: 3px 19px;
  }
}

.sec-blog {
  padding: 67px 0 73px;
}
@media screen and (max-width: 834px) {
  .sec-blog {
    padding: 40px 0 89px;
  }
}
.sec-blog .c-ttl__01 {
  margin-bottom: 51px;
}
@media screen and (max-width: 834px) {
  .sec-blog .c-ttl__01 {
    margin-bottom: 30px;
  }
}

.blog-list {
  max-width: 755px;
  width: 100%;
  margin: 0 auto 70px;
}
@media screen and (max-width: 834px) {
  .blog-list {
    max-width: calc(100% + 24px);
    width: calc(100% + 24px);
    margin: 0 -12px 40px;
  }
}
.blog-list__item {
  border-bottom: 1px solid #937a56;
  padding: 19px 22px 20px;
}
@media screen and (max-width: 834px) {
  .blog-list__item {
    padding: 11px 12px 12px 14px;
  }
}
.blog-list__item:first-child {
  border-top: 1px solid #937a56;
}
.blog-list__item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.blog-list__img {
  width: 145px;
  position: relative;
  overflow: hidden;
}
.blog-list__img picture, .blog-list__img img {
  width: 100%;
}
@media screen and (max-width: 834px) {
  .blog-list__img {
    width: 25%;
  }
}
.blog-list__img:before {
  display: block;
  content: "";
  padding-top: 100%;
}
.blog-list__img > :first-child {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-list__ct {
  padding-top: 7px;
  margin-bottom: -1px;
  width: calc(100% - 145px);
  padding-left: 46px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}
@media screen and (max-width: 834px) {
  .blog-list__ct {
    width: 75%;
    padding-left: 28px;
    padding-top: 5px;
  }
}
.blog-list__ttl {
  width: 100%;
  color: #937a56;
  font-weight: 300;
  margin-bottom: 23px;
  letter-spacing: 2px;
  font-size: 2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 640px) {
  .blog-list__ttl {
    letter-spacing: 1px;
    margin-bottom: 16px;
    font-size: 1.2rem;
  }
}
.blog-list__date {
  letter-spacing: 1.25px;
  width: 100%;
  color: #937a56;
  margin-bottom: 10px;
  font-size: 1.3rem;
}
@media screen and (max-width: 640px) {
  .blog-list__date {
    letter-spacing: 0;
    font-size: 1rem;
  }
}
.blog-list__cate {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 1.3rem;
}
@media screen and (max-width: 640px) {
  .blog-list__cate {
    text-align: right;
    font-size: 1rem;
  }
}
.blog-list__cate span {
  background: #c9b698;
  display: inline-block;
  padding: 4px 9px;
  letter-spacing: 1px;
}
@media screen and (max-width: 834px) {
  .blog-list__cate span {
    padding: 1px 5px 3px;
    letter-spacing: 0;
  }
}

.blog-page {
  padding: 96px 0 152px;
}
@media screen and (max-width: 834px) {
  .blog-page {
    padding: 37px 0 195px;
  }
}
.blog-page__des a {
  text-decoration: underline;
  text-underline-position: under;
  color: #665845;
}
.blog-page__list {
  display: grid;
  grid-template: repeat(1, 1fr);
  gap: 31px;
  margin-bottom: 123px;
}
@media screen and (max-width: 640px) {
  .blog-page__list {
    gap: 43px;
    margin-bottom: 79px;
  }
}
.blog-page__list > * {
  overflow: hidden;
}
.blog-page__list .is-hidden {
  display: none;
}
.blog-page__list-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #f8f0e4;
  box-shadow: 2px 0 8px rgba(29, 30, 30, 0.24);
  padding: 13px 14px 16px;
}
@media screen and (max-width: 640px) {
  .blog-page__list-wrap {
    padding: 16px 17px 54px;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .blog-page__list-wrap::before {
    content: "";
    position: absolute;
    bottom: 9px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-right: 3px solid #665845;
    border-bottom: 3px solid #665845;
  }
}
.blog-page__list-left {
  width: 25.1%;
  position: relative;
}
@media screen and (max-width: 640px) {
  .blog-page__list-left {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .blog-page__list-img {
    margin-bottom: 13px;
  }
}
.blog-page__list-img img {
  display: block;
  width: 100%;
}
.blog-page__list-status {
  position: absolute;
  top: 7px;
  right: 5px;
  background: #f7674b;
  color: white;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1;
  padding: 9px 8px 8px;
}
@media screen and (max-width: 834px) {
  .blog-page__list-status {
    font-size: 1.1845rem;
    padding: 10px;
  }
}
.blog-page__list-ct {
  width: 74.9%;
  padding: 8px 0 0 24px;
}
@media screen and (max-width: 640px) {
  .blog-page__list-ct {
    width: 100%;
    padding: 0;
  }
}
.blog-page__list-ct .c-txt__02 {
  font-weight: normal;
  color: #5e4a2e;
}
.blog-page__list-ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5555555556;
  margin-bottom: -1px;
  color: #5e4a2e;
}
@media screen and (max-width: 834px) {
  .blog-page__list-ttl {
    font-size: 1.895rem;
  }
}
@media screen and (max-width: 640px) {
  .blog-page__list-ttl {
    height: 28px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 23px;
  }
}
.blog-page__list-ttl a {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blog-page__list-date {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2.3333333333;
  margin-bottom: 14px;
}
@media screen and (max-width: 834px) {
  .blog-page__list-date {
    font-size: 1.421rem;
    margin-bottom: 6px;
  }
}
.blog-page__list-cate {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 15px;
  gap: 5px;
}
@media screen and (max-width: 834px) {
  .blog-page__list-cate {
    font-size: 1.1845rem;
    margin-bottom: 22px;
  }
}
.blog-page__list-cate > * {
  background: #665845;
  color: white;
  padding: 9px 10px 8px;
}
@media screen and (max-width: 834px) {
  .blog-page__list-cate > * {
    padding: 10px 19px;
  }
}
@media screen and (max-width: 834px) {
  .blog-page__list .c-txt__02 {
    padding: 0 3px;
  }
}

.article-page {
  padding: 89px 0 100px;
}
@media screen and (max-width: 834px) {
  .article-page {
    padding: 31px 0 89px;
  }
}
.article-page__date {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.125;
  font-weight: bold;
  color: #937a56;
  margin-bottom: 1px;
}
@media screen and (max-width: 834px) {
  .article-page__date {
    font-size: 1.5rem;
  }
}
.article-page__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4166666667;
  font-weight: bold;
  color: #937a56;
  border-bottom: 2px solid #b19c7a;
  position: relative;
  padding-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .article-page__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5555555556;
    border-bottom-width: 1px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 374px) {
  .article-page__ttl {
    font-size: 1.6rem;
  }
}
.article-page__ttl::before, .article-page__ttl::after {
  position: absolute;
  content: "";
  height: 10px;
  width: 160px;
  top: 100%;
}
@media screen and (max-width: 834px) {
  .article-page__ttl::before, .article-page__ttl::after {
    height: 5px;
    width: 80px;
  }
}
.article-page__ttl::before {
  left: 0;
  background: #ebe0ce;
}
.article-page__ttl::after {
  left: 160px;
  background: #b29d7c;
}
@media screen and (max-width: 834px) {
  .article-page__ttl::after {
    left: 80px;
  }
}
.article-page__tags {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 10px;
  gap: 10px;
  font-size: 1rem;
  font-weight: bold;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .article-page__tags {
    gap: 5px;
    margin-bottom: 5px;
  }
}
.article-page__tags > * {
  border: 1px solid #5e4a2e;
  color: #5e4a2e;
  padding: 6px 9px 5px;
}
.article-page__tags--fullColor > * {
  background: #5e4a2e;
  color: white;
}
.article-page__tags--brown {
  font-size: 1rem;
}
.article-page__tags--brown a {
  background: #665845;
  color: #fff;
}
.article-page__tags--02 {
  font-size: 1rem;
}
.article-page__tags--02 a {
  color: #665845;
}
.article-page__ct {
  margin-top: 24px;
  margin-bottom: 165px;
  color: #0a0204;
}
@media screen and (max-width: 834px) {
  .article-page__ct {
    margin: 40px -12px 73px;
  }
  .article-page__ct > *:not(img) {
    padding: 0 12px;
  }
}
.article-page__ct img {
  display: block;
  width: 100%;
  margin-bottom: 62px;
}
@media screen and (max-width: 834px) {
  .article-page__ct img {
    margin-bottom: 32px;
  }
}
.article-page__ct img:last-child {
  margin-bottom: 0;
}
.article-page__ct p {
  margin-bottom: 30px;
}
.article-page__ct p:last-child {
  margin-bottom: 0;
}
.article-page__action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: normal;
}
.article-page__action-center {
  width: 100%;
  margin-bottom: -32px;
  order: 0;
}
@media screen and (max-width: 640px) {
  .article-page__action-center {
    margin-bottom: 20px;
  }
}
.article-page__action .c-btn__02--arrLeft {
  order: 1;
  margin-right: auto;
}
.article-page__action .c-btn__02--arrRight {
  order: 2;
  margin-left: auto;
}

.contact-page {
  padding: 45px 0 148px;
}
@media screen and (max-width: 834px) {
  .contact-page {
    padding: 37px 0 90px;
  }
}
.contact-page__form {
  border: 1px solid #ad9877;
}
.contact-page__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ad9877;
  min-height: 79px;
}
@media screen and (max-width: 834px) {
  .contact-page__item {
    flex-wrap: wrap;
  }
}
.contact-page__item:last-child {
  border-bottom: none;
}
.contact-page__label {
  width: 25.4%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f7f5f1;
  border-right: 1px solid #ad9877;
  padding: 18px 25px 18px 19px;
}
@media screen and (max-width: 1024px) {
  .contact-page__label {
    padding: 18px 10px 18px 9px;
  }
}
@media screen and (max-width: 834px) {
  .contact-page__label {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ad9877;
    padding: 10px 16px 10px 17px;
    height: 59px;
  }
}
@media screen and (min-width: 835px) {
  .contact-page__label._start {
    align-items: flex-start;
    padding-top: 26px;
  }
  .contact-page__label._start .contact-page__require {
    margin-top: 1px;
  }
}
.contact-page__require {
  font-size: 1.2rem;
  line-height: 1.4;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: bold;
  background: #937a56;
  color: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  width: 50px;
  border-radius: 4px;
}
@media screen and (max-width: 834px) {
  .contact-page__require {
    font-size: 1.0755rem;
    height: 22px;
    width: 35px;
    border-radius: 2px;
  }
}
.contact-page__ct {
  width: 74.6%;
  max-width: 712px;
  padding: 18px 24px;
}
@media screen and (max-width: 834px) {
  .contact-page__ct {
    width: 100%;
    max-width: 100%;
    padding: 22px 19px;
  }
}
.contact-page__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px 38px;
  padding: 10px 0 0;
}
@media screen and (max-width: 640px) {
  .contact-page__list > * {
    width: 100%;
  }
}
.contact-page__list.isDisable {
  pointer-events: none;
}
.contact-page__address {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.8rem;
  margin-bottom: 18px;
}
.contact-page__address .form-control {
  width: 260px;
  margin: 0 20px 0 9px;
}
@media screen and (max-width: 834px) {
  .contact-page__address .form-control {
    width: 160px;
  }
}
.contact-page__address span {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1;
  width: 70px;
  height: 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border: 1px solid #ad9877;
  color: #937a56;
  border-radius: 4px;
  font-weight: bold;
}
.contact-page__address .error {
  display: block;
  width: 100%;
  border: none;
  order: 3;
  text-align: left;
  margin-top: 5px;
  font-size: 84% !important;
  font-weight: normal;
}
.contact-page__txt a {
  text-decoration: underline;
  text-underline-position: under;
  color: #937a56;
  margin-right: 5px;
}
.contact-page__privacy {
  max-width: 600px;
  margin: 0 auto;
  color: #0a0204;
}
.contact-page__privacy .pure-input,
.contact-page__privacy .mwform-checkbox-field label {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ad9877;
  background: #f7f5f1;
  padding: 23px 10px 22px;
}
@media screen and (max-width: 834px) {
  .contact-page__privacy .pure-input,
.contact-page__privacy .mwform-checkbox-field label {
    padding: 26px 10px;
  }
}
.contact-page__privacy .pure-input .isDisable,
.contact-page__privacy .mwform-checkbox-field label .isDisable {
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  .contact-page__privacy .pure-input input[type=checkbox] + span::before,
.contact-page__privacy .mwform-checkbox-field label input[type=checkbox] + span::before {
    width: 31px;
    height: 31px;
    left: calc(50% - 15px);
    bottom: 0;
    top: auto;
  }
}
@media screen and (max-width: 834px) {
  .contact-page__privacy .pure-input input[type=checkbox]:checked + span::after,
.contact-page__privacy .mwform-checkbox-field label input[type=checkbox]:checked + span::after {
    width: 21px;
    height: 10px;
    top: auto;
    bottom: 14px;
    left: calc(50% - 8px);
  }
}
.contact-page__privacy .pure-input > span,
.contact-page__privacy .mwform-checkbox-field label > span {
  padding: 0 7px 0 39px;
}
@media screen and (max-width: 834px) {
  .contact-page__privacy .pure-input > span,
.contact-page__privacy .mwform-checkbox-field label > span {
    padding: 0 0 55px;
  }
}
.contact-page__btn {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 40px;
}
.contact-page__btn .c-btn__01 {
  width: calc(50% - 20px);
  max-width: 380px;
  margin: 0;
}
@media screen and (max-width: 640px) {
  .contact-page__btn .c-btn__01 {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .contact-page__btn .c-btn__01:nth-child(1) {
    order: 1;
  }
}
@media screen and (min-width: 641px) {
  .contact-page__btn .c-btn__01:nth-child(2) {
    margin-left: auto;
  }
}
@media screen and (max-width: 640px) {
  .contact-page__btn .c-btn__01:nth-child(2) {
    order: 0;
  }
}

.mw_wp_form_confirm .input-block {
  display: none;
}
@media screen and (max-width: 834px) {
  .mw_wp_form_confirm .contact-page__ct {
    padding: 37px 35px;
  }
}
@media screen and (max-width: 374px) {
  .mw_wp_form_confirm .contact-page__ct {
    padding: 15px;
  }
}
@media screen and (min-width: 835px) {
  .mw_wp_form_confirm .contact-page__ct {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .mw_wp_form_confirm .contact-page__ct._block {
    display: block;
  }
}
.mw_wp_form_confirm .contact-page__address {
  width: 100%;
}
.mw_wp_form_confirm .contact-page__address span {
  display: none;
}

.contact-thanks {
  padding: 45px 0 201px;
}
@media screen and (max-width: 834px) {
  .contact-thanks {
    padding: 37px 0 90px;
  }
}
@media screen and (min-width: 835px) {
  .contact-thanks .c-txt__04 {
    letter-spacing: 0.06em;
    margin-bottom: 195px;
  }
}
@media screen and (max-width: 834px) {
  .contact-thanks .c-txt__04 {
    margin-bottom: 88px;
  }
}
@media screen and (min-width: 835px) {
  .contact-thanks .c-btn__01 {
    max-width: 380px;
  }
}

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0 !important;
}

.gallery-page {
  padding: 96px 0 151px;
}
@media screen and (max-width: 834px) {
  .gallery-page {
    padding: 36px 0 91px;
  }
}
.gallery-page__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  color: #5e4a2e;
}
@media screen and (max-width: 1024px) {
  .gallery-page__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 834px) {
  .gallery-page__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 640px) {
  .gallery-page__list {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    max-width: 352px;
    margin: 0 auto;
  }
}
.gallery-page__list > * {
  display: none;
}
.gallery-page__list > * > * {
  height: 100%;
}
.gallery-page__list .is-active {
  display: block;
}
.gallery-page__list-inn {
  display: block;
  padding: 16px 15px 13px;
  background: #f8f0e4;
  box-shadow: 2px 0 8px rgba(29, 30, 30, 0.24);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  height: 100%;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-inn {
    padding: 17px;
  }
}
.gallery-page__list-inn::after {
  position: absolute;
  z-index: -1;
  content: "";
  width: 16px;
  height: 16px;
  border-right: 3px solid #5e4a2e;
  border-bottom: 3px solid #5e4a2e;
  bottom: 11px;
  right: 10px;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-inn::after {
    width: 20px;
    height: 20px;
  }
}
.gallery-page__list-img {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.gallery-page__list-img:before {
  content: "";
  display: block;
  padding-bottom: 65.9176029963%;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-img {
    margin-bottom: 8px;
  }
}
.gallery-page__list-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.gallery-page__list-status {
  position: absolute;
  top: 6px;
  right: 4px;
  background: #f7674b;
  color: white;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: bold;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1;
  padding: 9px 7px;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-status {
    font-size: 1.1845rem;
    padding: 10px 8px;
    top: 7px;
    right: 6px;
  }
}
.gallery-page__list-ttl {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: bold;
  margin-bottom: 18px;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-ttl {
    font-size: 1.895rem;
    margin-bottom: 23px;
  }
}
.gallery-page__list-ttl a {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}
.gallery-page__list-tags {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 9px;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-tags {
    font-size: 1.1845rem;
    gap: 11px;
    margin-bottom: 6px;
  }
}
.gallery-page__list-tags > * {
  border: 1px solid #5e4a2e;
  padding: 8px 9px 7px;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-tags > * {
    padding: 9px 11px;
  }
}
.gallery-page__list-str {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 9px;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-str {
    font-size: 1.1845rem;
    gap: 11px;
  }
}
.gallery-page__list-str > * {
  border: 1px solid #5e4a2e;
  background: #5e4a2e;
  color: white;
  padding: 8px 9px 7px;
}
@media screen and (max-width: 834px) {
  .gallery-page__list-str > * {
    padding: 9px 12px;
  }
}

.sec-article {
  padding: 95px 0 100px;
}
@media screen and (max-width: 834px) {
  .sec-article {
    padding: 39px 0 205px;
  }
}

.article-slider {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 39px;
}
@media screen and (max-width: 834px) {
  .article-slider {
    margin: 0 -25px 8px;
  }
}
.article-slider__lt {
  width: 63.5%;
}
@media screen and (max-width: 834px) {
  .article-slider__lt {
    width: 100%;
    padding: 0 13px;
  }
}
.article-slider__rt {
  width: 31.4%;
}
@media screen and (max-width: 834px) {
  .article-slider__rt {
    width: 100%;
  }
}

.list-for__item {
  position: relative;
  overflow: hidden;
}
.list-for__item:before {
  content: "";
  display: block;
  padding-bottom: 75%;
}
.list-for__thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.list-for__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.list-for .slick-arrow {
  width: 40px;
  height: 60px;
  transition: 0.3s ease;
}
.list-for .slick-arrow:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 60px;
  background: url("../img/gallery/article/icon-arr.png") no-repeat center/cover;
  top: 0;
  left: 0;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .list-for .slick-arrow:before {
    width: 30px;
    height: 45px;
  }
}
.list-for .slick-arrow.slick-prev {
  left: 0;
  z-index: 2;
  transform: translateY(-50%) rotate(-180deg);
}
@media screen and (max-width: 834px) {
  .list-for .slick-arrow.slick-prev {
    left: 15px;
    top: 43%;
  }
}
.list-for .slick-arrow.slick-next {
  right: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .list-for .slick-arrow.slick-next {
    right: 15px;
    top: 49%;
  }
}
.list-for .slick-arrow:hover {
  transition: 0.3s ease;
}
.list-for .slick-arrow:hover::before {
  transition: 0.3s ease;
}

.list-nav {
  background: #f6f1e8;
  padding: 21px 8px 0;
}
@media screen and (max-width: 834px) {
  .list-nav {
    padding: 8px 0 8px 20px;
  }
}
@media screen and (min-width: 835px) {
  .list-nav .slick-track {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    transform: unset !important;
    max-height: clamp(340px, 40.718vw, 505px);
    overflow-y: auto;
    scrollbar-width: auto;
    scrollbar-color: #ccc #F3F3F3;
  }
  .list-nav .slick-track::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }
  .list-nav .slick-track::-webkit-scrollbar-track {
    background: #F3F3F3;
  }
  .list-nav .slick-track::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 1px solid #F3F3F3;
  }
}
@media screen and (min-width: 835px) {
  .list-nav .slick-cloned {
    display: none;
  }
}
@media screen and (min-width: 835px) {
  .list-nav__item {
    padding: 0 9px;
    width: 50% !important;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 834px) {
  .list-nav__item {
    padding: 0 5px;
  }
}
.list-nav__item.slick-active .list-nav__img-thumbnail {
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .list-nav__item.slick-active .list-nav__img-thumbnail {
    border: none;
  }
}
.list-nav__item.slick-active .list-nav__img:hover {
  opacity: 1;
  cursor: default;
}
.list-nav__img {
  position: relative;
  overflow: hidden;
  transition: 0.3s ease;
}
.list-nav__img:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
}
.list-nav__img:before {
  content: "";
  display: block;
  padding-bottom: 74.8299319728%;
}
@media screen and (max-width: 834px) {
  .list-nav__img:before {
    padding-bottom: 70.566474%;
  }
}
.list-nav__img-thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease;
}
.list-nav__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.list-nav .slick-arrow {
  transition: 0.3s ease;
}
.list-nav .slick-arrow.slick-prev {
  display: none !important;
}
.list-nav .slick-arrow.slick-next {
  width: 100%;
  height: 40px;
  background: rgba(83, 67, 46, 0.68);
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(0);
}
.list-nav .slick-arrow.slick-next:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 25px;
  height: 15px;
  background: url("../img/gallery/article/icon-arr02.png") no-repeat center/cover;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translate(-50%, -45%);
}
@media screen and (max-width: 834px) {
  .list-nav .slick-arrow.slick-next {
    display: none !important;
  }
}
.list-nav .slick-arrow:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

.article-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 56px;
}
@media screen and (max-width: 834px) {
  .article-box {
    margin-bottom: 5px;
  }
}
.article-box__lt {
  width: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .article-box__lt {
    width: 100%;
    padding: 0 6%;
  }
}
.article-box__rt {
  width: 50%;
  padding-top: 23px;
  color: #0a0204;
}
@media screen and (max-width: 834px) {
  .article-box__rt {
    width: 100%;
    order: -1;
    margin-bottom: 41px;
  }
}
.article-box__item {
  width: 46%;
  padding-right: 30px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .article-box__item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 43px;
  }
}
.article-box__item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid #aeaeae;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}
@media screen and (max-width: 834px) {
  .article-box__item:before {
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 20px solid #aeaeae;
    right: 47%;
    top: auto;
    bottom: -75px;
    transform: rotate(90deg);
  }
}
.article-box__item-ttl {
  text-align: left;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 834px) {
  .article-box__item-ttl {
    font-size: 2.75rem;
  }
}
.article-box__item-img {
  position: relative;
  overflow: hidden;
  border: 20px #f0f0f0 solid;
  border-width: 20px 28px;
}
@media screen and (max-width: 834px) {
  .article-box__item-img {
    border-width: 26px 36px;
  }
}
.article-box__item-img:before {
  content: "";
  display: block;
  padding-bottom: 75%;
}
.article-box__item-img picture {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.article-box__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-box__item--02 {
  width: 54%;
}
@media screen and (max-width: 834px) {
  .article-box__item--02 {
    width: 100%;
    margin-bottom: 37px;
  }
}
.article-box__item--02:before {
  display: none;
}
.article-box__item--02 .article-box__item-img {
  border-color: #f6f1e8;
  border-width: 24px 30px;
}
@media screen and (max-width: 834px) {
  .article-box__item--02 .article-box__item-img {
    border-width: 26px 36px;
  }
}
.article-box__item--02 .article-box__item-img:before {
  padding-bottom: 75.1219512195%;
}

.article-table {
  width: 100%;
  color: #0a0204;
  margin-bottom: 152px;
}
@media screen and (max-width: 834px) {
  .article-table {
    font-size: 1.5rem;
    margin-bottom: 87px;
  }
}
@media screen and (max-width: 834px) {
  .article-table tbody {
    display: block;
  }
}
@media screen and (max-width: 834px) {
  .article-table tr {
    width: 100%;
    display: block;
  }
}
.article-table tr th {
  background: #f7f5f1;
  padding: 9px 20px;
  border: 1px #ad9877 solid;
  width: 250px;
  vertical-align: top;
  font-weight: 300;
}
@media screen and (max-width: 834px) {
  .article-table tr th {
    width: 100%;
    display: block;
    border-bottom: none;
    padding: 16px 15px;
  }
}
.article-table tr td {
  padding: 9px 19px;
  border: 1px #ad9877 solid;
}
@media screen and (max-width: 834px) {
  .article-table tr td {
    width: 100%;
    display: block;
    border-bottom: none;
    padding: 16px 15px;
  }
}
@media screen and (max-width: 834px) {
  .article-table tr:last-child td {
    border-bottom: 1px #ad9877 solid;
  }
}
.article-table--02 {
  margin-bottom: 43px;
}
@media screen and (min-width: 835px) {
  .article-table--02 tr th {
    padding: 10px 20px;
  }
}
.article-table--02 tr td {
  padding: 10px 19px;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 834px) {
  .article-table--02 tr td {
    line-height: 1.6;
    padding: 17px;
    letter-spacing: 0;
  }
}
.article-table--03 {
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .article-table--03 {
    margin-bottom: 42px;
  }
}
@media screen and (min-width: 835px) {
  .article-table--03 tr th {
    padding: 10px 20px;
    width: 199px;
  }
}
.article-table--03 tr td {
  padding: 10px 26px;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 834px) {
  .article-table--03 tr td {
    line-height: 1.6;
    padding: 16px 17px 15px;
    letter-spacing: 0;
  }
}

.concept-page {
  padding: 97px 0 170px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .concept-page {
    padding: 35px 0 91px;
  }
}
.concept-page__box {
  position: relative;
  z-index: 1;
  color: #0a0204;
}
@media screen and (min-width: 641px) {
  .concept-page__box {
    margin-left: auto;
    width: 70.6%;
  }
}
@media screen and (max-width: 834px) {
  .concept-page__box {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7857142857;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 640px) {
  .concept-page__box {
    padding-bottom: 164px;
    margin-bottom: 0;
  }
}
.concept-page__box::before {
  position: absolute;
  z-index: -1;
  content: "";
  width: 429px;
  height: 412px;
  background: url(../img/concept/bg.jpg) center/contain no-repeat;
  right: 105.7%;
  top: 1px;
}
@media screen and (max-width: 640px) {
  .concept-page__box::before {
    background: url(../img/concept/bg-sp.jpg) center/contain no-repeat;
    width: 375px;
    height: 248px;
    top: auto;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.concept-page__box-ttl {
  font-size: 2.4rem;
  line-height: 1.4;
  font-weight: bold;
  color: #937a56;
}
@media screen and (max-width: 834px) {
  .concept-page__box-ttl {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5555555556;
  }
}
@media screen and (max-width: 640px) {
  .concept-page__box-ttl {
    padding: 0 20px;
  }
}
.concept-page .c-ttl__02 {
  position: relative;
  z-index: 2;
}
.concept-page__des {
  position: relative;
  z-index: 2;
  color: #0a0204;
}
@media screen and (min-width: 641px) {
  .concept-page__des {
    text-align: center;
  }
}
@media screen and (max-width: 834px) {
  .concept-page__des {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7857142857;
  }
}
.concept-page__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .concept-page__flex {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 640px) {
  .concept-page__flex {
    display: block;
  }
}
.concept-page__img {
  width: 260px;
  position: relative;
  z-index: 1;
}
.concept-page__img::before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #f4e7d4;
  width: 100%;
  height: 100%;
  top: 28px;
  right: 20px;
}
.concept-page__info {
  margin-right: -2px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 26px;
}
@media screen and (max-width: 640px) {
  .concept-page__info {
    display: block;
    padding: 0 19px;
    margin: 0 auto;
    max-width: 351px;
  }
}
.concept-page__avata {
  width: 260px;
  position: relative;
  z-index: 1;
  order: 1;
}
@media screen and (max-width: 640px) {
  .concept-page__avata {
    width: 100%;
    max-width: 312px;
    padding-right: 20px;
    margin-bottom: 54px;
  }
}
.concept-page__avata::before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #f4e7d4;
  width: 100%;
  height: 100%;
  top: 24px;
  right: -19px;
}
@media screen and (max-width: 640px) {
  .concept-page__avata::before {
    top: 27px;
    right: 0;
    width: calc(100% - 20px);
  }
}
.concept-page__ct {
  order: 0;
}
.concept-page__name {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.0795;
  margin-bottom: 14px;
}
@media screen and (max-width: 640px) {
  .concept-page__name {
    padding: 0 3px;
    text-align: right;
    margin-bottom: 53px;
  }
}
.concept-page__sns {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 20px;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .concept-page__sns {
    padding: 0 2px 0 0;
    gap: 42px;
  }
}
@media screen and (max-width: 374px) {
  .concept-page__sns {
    gap: 15px;
  }
}
.concept-page__sns > * {
  width: 36px;
  height: 36px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #937a56;
  color: white;
  border-radius: 10px;
}
@media screen and (max-width: 640px) {
  .concept-page__sns > * {
    width: 75px;
    height: 75px;
    border-radius: 19px;
  }
}
.concept-page__sns > *:first-child {
  font-size: 3rem;
}
@media screen and (max-width: 640px) {
  .concept-page__sns > *:first-child {
    font-size: 6.5rem;
  }
}
.concept-page__sns > *:nth-child(2) {
  font-size: 2.4rem;
}
@media screen and (max-width: 640px) {
  .concept-page__sns > *:nth-child(2) {
    font-size: 4.6rem;
  }
}
.concept-page__sns > *:last-child {
  font-size: 2.2rem;
}
@media screen and (max-width: 640px) {
  .concept-page__sns > *:last-child {
    font-size: 5.5rem;
  }
}

.sec-company {
  padding: 44px 0 50px;
}
@media screen and (max-width: 834px) {
  .sec-company {
    padding: 39px 0 25px;
  }
}
.sec-company__ttl {
  color: #937a56;
}

.company-list__item {
  padding-left: 20px;
  margin-bottom: 24px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .company-list__item {
    margin-bottom: 20px;
  }
}
.company-list__item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: url("../img/company/icon-check.png") no-repeat center/cover;
  top: 6px;
  left: 0;
}
@media screen and (max-width: 834px) {
  .company-list__item:before {
    width: 14px;
    height: 14px;
    top: 5px;
  }
}
.company-list__item:last-child {
  margin-bottom: 14px;
}
.company-list__item-ttl {
  font-weight: 700;
}

.sec-access {
  padding-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .sec-access {
    padding-bottom: 49px;
  }
}
.sec-access__map {
  margin-bottom: 28px;
}
@media screen and (max-width: 834px) {
  .sec-access__map {
    margin-bottom: 36px;
  }
}
.sec-access__map iframe {
  border: none;
  width: 100%;
}
@media screen and (max-width: 834px) {
  .sec-access__map iframe {
    height: 350px;
  }
}

.access-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.access-block__lt {
  width: 65.5%;
  padding-right: 6.5%;
}
@media screen and (max-width: 834px) {
  .access-block__lt {
    width: 100%;
    padding-right: 0;
  }
}
.access-block__rt {
  width: 34.5%;
  margin-top: -125px;
}
@media screen and (max-width: 834px) {
  .access-block__rt {
    width: 100%;
    margin-top: 0;
  }
}
.access-block__img {
  margin-bottom: 63px;
}
.access-block__btn {
  max-width: 380px;
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  .access-block__btn {
    height: 80px;
    margin-bottom: 41px;
  }
  .access-block__btn:before {
    height: 80px;
    top: 0;
    width: 36px;
  }
  .access-block__btn:after {
    height: 80px;
    top: 0;
    width: 36px;
  }
}

.service-page {
  padding: 96px 0 101px;
}
@media screen and (max-width: 834px) {
  .service-page {
    padding: 35px 0 102px;
  }
}
.service-page .c-txt__01 {
  color: #0a0204;
}
@media screen and (max-width: 640px) {
  .service-page__ttl {
    text-align: left;
    padding: 0 20px;
  }
}
.service-page__list {
  counter-reset: countNumber;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 94px;
}
@media screen and (max-width: 834px) {
  .service-page__list {
    gap: 80px;
  }
}
.service-page__list > * {
  counter-increment: countNumber;
}
.service-page__list-ttl {
  font-size: 3.197rem;
  font-weight: 700;
  color: #877962;
  position: relative;
  padding-left: 38px;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .service-page__list-ttl {
    font-size: 2rem;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 640px) {
  .service-page__list-ttl {
    padding: 0;
  }
}
@media screen and (max-width: 374px) {
  .service-page__list-ttl {
    font-size: 1.8rem;
  }
}
.service-page__list-ttl::before {
  position: absolute;
  top: -17px;
  left: -22px;
  content: counter(countNumber);
  background: #b1513e;
  color: white;
  border-radius: 50%;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1;
  width: 45px;
  height: 45px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .service-page__list-ttl::before {
    left: -8px;
  }
}
@media screen and (max-width: 640px) {
  .service-page__list-ttl::before {
    position: static;
    margin: 0 auto 11px;
  }
}
.service-page__list-str {
  background: #877962;
  color: white;
  font-size: 1.998rem;
  font-weight: 700;
  padding: 14px 16px;
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .service-page__list-str {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5714285714;
    padding: 6px 22px 5px 10px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 374px) {
  .service-page__list-str {
    font-size: 1.3rem;
  }
}
.service-page__list-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .service-page__list-flex {
    align-items: flex-start;
  }
}
@media screen and (max-width: 640px) {
  .service-page__list-flex {
    display: block;
  }
}
.service-page__list-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media screen and (min-width: 641px) {
  .service-page__list-group {
    width: 45.8%;
  }
}
@media screen and (max-width: 640px) {
  .service-page__list-group {
    gap: 6px;
    margin-bottom: 22px;
  }
}
.service-page__list-ct {
  position: relative;
  padding-bottom: 85px;
}
@media screen and (min-width: 641px) {
  .service-page__list-ct {
    width: 50%;
  }
}
@media screen and (max-width: 834px) {
  .service-page__list-ct {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7857142857;
  }
}
@media screen and (max-width: 640px) {
  .service-page__list-ct {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 641px) {
  .service-page__list-txt {
    margin-top: -9px;
  }
}
@media screen and (max-width: 640px) {
  .service-page__list-txt {
    margin-bottom: 19px;
  }
}
.service-page__list-btn {
  width: 100%;
  max-width: 100%;
}
@media screen and (min-width: 641px) {
  .service-page__list-btn {
    position: absolute;
    bottom: 1px;
    left: 0;
  }
}

.sec-voice {
  padding: 97px 0 153px;
}
@media screen and (max-width: 834px) {
  .sec-voice {
    padding: 36px 0 83px;
  }
}

.voice-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #0a0204;
}
@media screen and (max-width: 834px) {
  .voice-block {
    margin: 0 -12px;
  }
}
.voice-block__lt {
  width: 50%;
  padding: 19px 20px 216px;
  background: #fffaf1;
}
@media screen and (max-width: 834px) {
  .voice-block__lt {
    width: 100%;
    padding: 29px 15px 15px;
    margin-bottom: 55px;
  }
}
.voice-block__rt {
  width: 50%;
  padding-left: 37px;
}
@media screen and (max-width: 834px) {
  .voice-block__rt {
    width: 100%;
    padding: 0 11px;
  }
}
.voice-block__rt p a {
  color: #b29d7c;
  display: inline;
}
.voice-block__ttl {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .voice-block__ttl {
    margin-bottom: 6px;
  }
}
.voice-block p {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .voice-block p {
    margin-bottom: 25px;
  }
  .voice-block p:last-child {
    margin-bottom: 0;
  }
}
.voice-block p .red {
  color: #ff004b;
}
.voice-block p a {
  text-decoration: underline;
}

/* ====================================================
Module
==================================================== */
/* ====================================================
Pages
==================================================== */