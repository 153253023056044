@use "vars" as *;

/* ====================================================
HTML
==================================================== */
html {
    font-size: 62.5%;
}

html.ov{
	overflow: hidden;
}

body {
	color: #0a0204;
	@include font-fm($font-base, 300); 
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.3;
	background: #fff;
	overflow-x: hidden;
}

a {
	display: inline-block;
	color: inherit;
    text-decoration: none;
    outline: none;
	@include smooth-transition;
	&:hover {
		opacity: 0.7;
		transition: all 0.3s;
	}
	&[href^="tel"] {
		@include mq(md-min) {
			pointer-events: none;
		}
	}
	@include mq(){
		-webkit-tap-highlight-color: transparent;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
