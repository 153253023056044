@use "vars" as *;

/* ====================================================
PAGE INDEX
==================================================== */
#page-index{
	&.wrapper{
		padding-top: 0!important;
	}	
}

.mv-index{
	position: relative;
	&__slider{
		margin-bottom: 0 !important;
		&-item{
			box-shadow: none !important;
			@include mq(){
				@include aspect-ratio(375px,301px);
			}
		}
		.slick-dots{
			bottom: 14px;
			@include mq(){
				display: none !important;
			}
			li{
				margin: 0 5px;
				width: 62px;
				height: 10px;
				background-color: rgba(255, 255, 255, 0.4);
				button{
					display: none;
				}
				&.slick-active{
					background: #fff;
				}
			}
		}
	}
	&__desc{
		z-index: 10;
		position: absolute;
		font-weight: 700;
		color: #fff;
		bottom: 81px;
		right: 149px;
		
		font-family: $font-base;
		line-height: 1.5428;
		text-shadow: 1px 0 7px rgba(0, 0, 0, 0.1);
		@include font-size(21);
		@include mq(xl){
			right: 50px;
		}
		@include mq(){
			right: auto;
			left: 13px;
			bottom: 22px;
			letter-spacing: 0.25px;
			@include font-size(14);
		}
	}
}

.sec-concept{
	padding: 67px 0 80px;
	@include mq(){
		padding: 41px 0 89px;
	}
}

.concept-flex{
	@include flex();
	margin-bottom: 61px;
	@include mq(){
		margin-bottom: 39px;
		flex-wrap: wrap;
	}
	&__ct{
		width: 50%;
		padding-top: 23px;
		@include mq(xl){
			padding-top: 0;
		}
		@include mq(){
			width: 100%;
			padding: 0 20px;
			margin-bottom: 50px;
		}
		@include mq(xs){
			padding: 0;
		}
		.c-txt__01{
			color: #484848;
		}
	}
	&__ttl{
		color: #937a56;
		font-weight: bold;
		
		margin-bottom: 34px;
		@include font-size(24);
		@include mq(xl){
			margin-bottom: 20px;
			@include font-size(22);
		}
		@include mq(){
			margin-bottom: 26px;
			line-height: 1.556;
			@include font-size(18);
		}
	}
	&__img{
		width: 50%;
		padding: 0 37px 0 45px;
		@include mq(xl){
			padding-left: 30px;
			padding-right: 0;
		}
		@include mq(){
			width: 100%;
			padding: 0 16px 0 13px;
		}
		picture,img{
			width: 100%;
		}
	}
}

.sec-service{
	padding: 70px 0 81px;
	background: url('../img/index/bg-01.png') center no-repeat;
	background-size: cover;
	@include mq(){
		padding: 37px 0 81px;
		background: url('../img/index/bg-01-sp.jpg') top center no-repeat;
		background-size: cover;
	}
	.c-ttl__01{
		margin-bottom: 21px;
		@include mq(){
			margin-bottom: 26px;
		}
	}
}

.service-block{
	margin-bottom: 54px;
	@include mq(){
		margin-bottom: 35px;
		padding: 0 20px;
	}
	@include mq(xs){
		padding: 0;
	}
	&__ttl{
		text-align: center;
		font-weight: 700;
		
		color: #937a56;
		@include font-size(24);
		@include mq(){
			text-align: left;
			line-height: 1.556;
			@include font-size(18);
		}
	}
}

.service-list{
	@include flex();
    max-width: 940px;
    width: 100%;
    margin: 0 auto 70px;
    // padding-right: 4px;
	background: white;
	box-shadow: 1px 0 7px rgba(0, 0, 0, 0.1);
	@include mq(){
		flex-wrap: wrap;
		padding-right: 0;
		margin: 0 -12px 43px;
		width: calc(100% + 24px);
	}
	&__item{
		width: 33.33%;
		@include mq(){
			width: 100%;
		}
		&:nth-child(2n){
			.service-list__img{
				order: 2;
			}
			.service-list__ct{
				order: 1;
			}
		}
	}
	&__flex{
		// height: 100%;
		@include flex(0,0,wrap);
		background: #fff;
		@include mq(){
			align-items: center;
		}
		@include mq(sm){
			align-items: flex-start;
		}
	}
	&__ct{
		width: 100%;
		position: relative;
		@include mq(){
			width: 50%;
		}
	}
	&__img{
		width: 100%;
		@include aspect-ratio(366px,365px);
	}
	&__children{
		position: absolute;
		top: 30px;
		left: 30px;
		background-color: rgba(67, 56, 42, 0.451);
		width: calc(100% - 60px);
		height: calc(100% - 60px);
		border: 1px solid #fff;
		text-align: center;
		@include flex(center,center,wrap,start);
		padding: 33px 0;
		@include mq(xl){
			top: 20px;
			left: 20px;
			width: calc(100% - 40px);
			height: calc(100% - 40px);
		}
		@include mq(){
			padding: 18px 0 56px;
			top: 17px;
			left: 18px;
			align-items: center;
			align-content: center;
			width: calc(100% - 36px);
			height: calc(100% - 34px);
		}
		p{
			color: #fff;
			width: 100%;
			font-weight: 700;
			
			@include font-size(19);
			@include mq(xl){
				@include font-size(15);
			}
			@include mq(){
				line-height: 1.32;
				@include font-size(11.5);
			}
		}
	}
	&__ttl{
		@include font-size(30);
		color: #fff;
		letter-spacing: 2px;
		width: 100%;
		text-shadow: rgb(142, 130, 114) 3px 0px 0px, rgb(142, 130, 114) 2.83487px 0.981584px 0px, rgb(142, 130, 114) 2.35766px 1.85511px 0px, rgb(142, 130, 114) 1.62091px 2.52441px 0px, rgb(142, 130, 114) 0.705713px 2.91581px 0px, rgb(142, 130, 114) -0.287171px 2.98622px 0px, rgb(142, 130, 114) -1.24844px 2.72789px 0px, rgb(142, 130, 114) -2.07227px 2.16926px 0px, rgb(142, 130, 114) -2.66798px 1.37182px 0px, rgb(142, 130, 114) -2.96998px 0.42336px 0px, rgb(142, 130, 114) -2.94502px -0.571704px 0px, rgb(142, 130, 114) -2.59586px -1.50383px 0px, rgb(142, 130, 114) -1.96093px -2.27041px 0px, rgb(142, 130, 114) -1.11013px -2.78704px 0px, rgb(142, 130, 114) -0.137119px -2.99686px 0px, rgb(142, 130, 114) 0.850987px -2.87677px 0px, rgb(142, 130, 114) 1.74541px -2.43999px 0px, rgb(142, 130, 114) 2.44769px -1.73459px 0px, rgb(142, 130, 114) 2.88051px -0.838247px 0px;
		font-weight: 400;
		// font-family: $font-gothic;
		margin-bottom: 27px;
		@include mq(xl){
			margin-bottom: 15px;
		}
		@include mq(){
			letter-spacing: 1px;
			@include font-size(18);
		}
	}
	&__desc{
		// min-height: 311px;
		padding: 35px 22px;
		@include fts(17,27.2);
		@include mq(xl){
			padding: 30px 20px;
			@include fts(15,24);
		}
		@include mq(){
			width: 50%;
		}
		@include mq(sm){
			padding: 17px 14px;
			@include fts(11,20);
		}
	}
}

.sec-gallery{
	padding: 55px 0 68px;
	background: url('../img/index/bg-02.jpg') center no-repeat;
	background-size: cover;
	@include mq(){
		padding: 36px 0 79px;
		background: url('../img/index/bg-02-sp.jpg') center no-repeat;
		background-size: cover;
	}
	.c-ttl__01{
		margin-bottom: 54px;
		@include mq(){
			margin-bottom: 32px;
		}
	}
}

.gallery-list{
	@include flex(0,0,wrap);
	margin-bottom: 57px;
	@include mq(){
		margin-bottom: 39px;
	}
	&__item{
		width: 25%;
		@include mq(){
			width: 50%;
		}
		a{
			display: block;
			position: relative;
			@include mq(md-min){
				&:hover{
					opacity: 1;
					.gallery-list__ct{
						opacity: 1;
						pointer-events: auto;
						transition: all 0.5s;
					}
				}
			}
		}
	}
	&__img{
		@include aspect-ratio(700px,476px);
	}
	&__ct{
		opacity: 0;
		pointer-events: none;
		transition: all 0.5s;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		text-align: center;
		padding: 20px;
		background: rgba(0, 0, 0, 0.439);
		border: 4px solid #fff;
		color: #fff;
		@include flex(center,center,wrap,center);
		@include mq(){
			pointer-events: auto;
			opacity: 1;
			border-width: 2px;
		}
		p{
			margin-bottom: 20px;
			font-weight: 700;			
			position: relative;
			z-index: 10;
			margin-bottom: 54px;
			@include font-size(18);
			@include mq(xl){
				margin-bottom: 0;
				@include font-size(16);
			}
			@include mq(sm){
				@include font-size(10);
			}
			span{
				display: block;
				border: 1px solid #fff;
				padding: 6px 15px;
				margin-top: 30px;
				@include mq(xl){
					margin-top: 15px;
				}
				@include mq(){
					padding: 3px 19px;
				}
			}
		}
		
	}
}

.sec-blog{
	padding: 67px 0 73px;
	@include mq(){
		padding: 40px 0 89px;
	}
	.c-ttl__01{
		margin-bottom: 51px;
		@include mq(){
			margin-bottom: 30px;
		}
	}
}

.blog-list{
	max-width: 755px;
	width: 100%;
	margin: 0 auto 70px;
	@include mq(){
		max-width: calc(100% + 24px);
		width: calc(100% + 24px);
		margin: 0 -12px 40px;
	}
	&__item{
		border-bottom: 1px solid #937a56;
		padding: 19px 22px 20px;
		@include mq(){
			padding: 11px 12px 12px 14px;
		}
		&:first-child{
			border-top: 1px solid #937a56;
		}
		a{
			@include flex(0,start);
		}
	}
	&__img{
		width: 145px;
		picture,img{
			width: 100%;
		}
		@include mq(){
			width: 25%;
		}
		@include aspect-ratio(145px,145px);
	}
	&__ct{
		padding-top: 7px;
		margin-bottom: -1px;
		width: calc(100% - 145px);
		padding-left: 46px;
		@include flex(0,0,wrap,between);
		@include mq(){
			width: 75%;
		    padding-left: 28px;
			padding-top: 5px;
		}
	}
	&__ttl{
		width: 100%;
		color: #937a56;
		font-weight: 300;
		margin-bottom: 23px;
		letter-spacing: 2px;
		@include font-size(20);
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
				line-clamp: 2; 
		-webkit-box-orient: vertical;
		@include mq(sm){
			letter-spacing: 1px;
			margin-bottom: 16px;
			@include font-size(12);
		}
	}
	&__date{
		letter-spacing: 1.25px;
		width: 100%;
		color: #937a56;
		margin-bottom: 10px;
		@include font-size(13);
		@include mq(sm){
			letter-spacing: 0;
			@include font-size(10);
		}
	}
	&__cate{
		width: 100%;
		font-weight: 500;
		color: #fff;
		
		@include font-size(13);
		@include mq(sm){
			text-align: right;
			@include font-size(10);
		}
		span{
			background: #c9b698;
			display: inline-block;
			padding: 4px 9px;
			letter-spacing: 1px;
			@include mq(){
				padding: 1px 5px 3px;
				letter-spacing: 0;
			}
		}
	}
}